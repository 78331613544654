export default {
    NetworkError: "Network Error!",
    error_403: "Access denied!",

    "error-500": "Server error! Try again",
    "error-408": "Can't access to A6 Touch Panel!",
    "error-required": "Required!",
    "error-deviceid": "Check your Device Id!",
    "error-nodevice": "Can't access to A6 Touch Panel!",

    database: {
        updating: "Database updating...",
        updateSuccess: "Database updated!",
        updateFailed: "Database could not be updated!"
    },

    settings: {
        loaded: "Ayarlar güncellendi",
        saved: "Ayarlar kaydedildi.",
        failed: "Ayarlar kaydedilemedi!"
    },
    connectingToDevice: "Connecting to device...",
    connectingToDeviceTip: "Make sure your device has access to the internet.",

    connection: {
        connected: "Connected",
        disconnected: "Disconnected!",

    },

    pwa: {
        updateButton: "UPDATE",
        updateNotify: "An update is available"
    },

    privacy: {
        gdprCheck: "I have read and approve the clarification text regarding the Personal Data Protection Law.",
        gdpr: "General Data Protection regulation (GPDR)",
        cookies: "Privacy Policy"
    }
};