import Firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";

// Initialize Firebase
export const firebaseConfig = {
    apiKey: "AIzaSyA4tSFqXO7h5ZN9yrjq5B0B7pxc930TgRw",
    authDomain: "smart-security-93b75.firebaseapp.com",
    databaseURL: "https://smart-security-93b75.firebaseio.com",
    projectId: "smart-security-93b75",
    storageBucket: "smart-security-93b75.appspot.com",
    messagingSenderId: "334814992324",
    appId: "1:334814992324:web:184e7426e822c6631a7691",
    measurementId: "G-M46BYB6CEB"
  };
export const firebase = Firebase;
export const firebaseApp = Firebase.initializeApp(firebaseConfig);
export const analytics = {
    setUser(userId){
        console.log("setUser", userId);
        firebaseApp.analytics().setUserId(userId);
    },
    logEvent(key, content){
        //console.log("logEvent", key, content);
        firebaseApp.analytics().logEvent(key, content);
    }
}