export default {
    switchOn: "On",
    switchOff: "Off",
    stateUp: "Up",
    stateDown: "Down",
    stateStop: "Stop",

    modal: {
        close: "Close",
        cancel: "Cancel",
        confirm: "SET"
    },

    thermostat: {
        modeCooling: "Cooling",
        modeHeating: "Heating",
        modeFan: "Fan",
        modeDry: "Dry",

        speedAuto: "Auto",
        speedMin: "Min",
        speedLow: "Low",
        speedMedium: "Medium",
        speedHigh: "High"
    }

};