var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"dimmer-card",class:[
  { 'in-progress': _vm.inProgress },
],attrs:{"elevation":_vm.elevation,"dark":_vm.dark,"disablex":_vm.inProgress ||
  !_vm.$props.device ||
  _vm.$props.device.state == 2 ||
  _vm.$props.device.state === undefined,"disabled":_vm.inProgress || !_vm.$props.device}},[_c('v-card-text',{staticStyle:{"flex":"1"},style:(_vm.cardColor),on:{"click":_vm.toggle}},[_c('div',[_c('div',{staticClass:"text-body-2"},[_c('strong',[_vm._v(_vm._s(_vm.device && _vm.device.name))]),_c('br'),_vm._v(_vm._s(_vm.device && _vm.roomName)+" ")]),_c('iconic',{attrs:{"value":_vm.deviceIcon}}),(_vm.device && _vm.device.state && !isNaN(_vm.device.state.toString()))?_c('span',{staticClass:"value",domProps:{"innerHTML":_vm._s(`${_vm.dimmerRatio}`)}}):_c('span',{staticClass:"value"},[_vm._v("——")])],1)]),_c('v-divider'),_c('v-btn',{attrs:{"text":"","color":"grey"},on:{"click":function($event){_vm.dialog = true}}},[(!_vm.inProgress)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-fw fa-ellipsis-h")]):_vm._e(),(_vm.inProgress)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-cog fa-spin")]):_vm._e()],1),_c('v-dialog',{attrs:{"width":_vm.width},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"dimmer-modal"},[_c('v-card-title',{class:_vm.headlineClass},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"mt-9 text-h4 grey--text text--lighten-1 lead-text",domProps:{"innerHTML":_vm._s(_vm.dimmerRatio)}}),_c('div',{staticClass:"mt-16 text-center"},[_c('i',{staticClass:"far fa-8x fa-lightbulb ml-8",style:(`color: ${_vm.$refs && _vm.$refs.dimmer && _vm.$refs.dimmer.stepColor
                }; text-shadow: 0 0 ${_vm.iconShadow}px ${_vm.$refs && _vm.$refs.dimmer && _vm.$refs.dimmer.stepColor}`)})])]),_c('v-col',{attrs:{"cols":"3"}},[_c('dimmer',_vm._b({ref:"dimmer",attrs:{"min":10,"max":100,"width":"100%"},on:{"stepColor":(v) => _vm.stepColor = v},model:{value:(_vm.dimmerValue),callback:function ($$v) {_vm.dimmerValue=$$v},expression:"dimmerValue"}},'dimmer',_vm.$props,false))],1),_c('v-col',{staticClass:"text-left",attrs:{"cols":"3","align-self":"end"}},[_c('div',[_c('v-btn',{directives:[{name:"holdable",rawName:"v-holdable",value:(_vm.increase),expression:"increase"}],attrs:{"text":"","icon":""},on:{"click":_vm.increase}},[_c('v-icon',[_vm._v("fas fa-fw fa-plus")])],1)],1),_c('div',{staticClass:"mt-10"},[_c('v-btn',{directives:[{name:"holdable",rawName:"v-holdable",value:(_vm.decrease),expression:"decrease"}],attrs:{"text":"","icon":""},on:{"click":_vm.decrease}},[_c('v-icon',[_vm._v("fas fa-fw fa-minus")])],1)],1),_c('div',{staticClass:"mt-16 mb-16"},[_c('v-btn',{attrs:{"text":"","icon":"","elevation":"0","color":"grey"},on:{"click":_vm.toggle}},[_c('v-icon',{class:{ 'green--text': _vm.$props.device.state == 1 }},[_vm._v("fas fa-fw fa-power-off")])],1)],1)])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","elevation":"0","text":"","block":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" × "+_vm._s(_vm.$t("touchable.modal.close"))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }