export default {
    info: {
        connectionReminder: "Oturum açabilmeniz için kontrol cihazınızın İnternet'e bağlı olması gerektiğini unutmayın.",
        helpText: "Oturum açmakta sorun yaşıyorsanız yardım başlıklarını inceleyin…",
        confirmSignOut: "Oturumu kapatmak istediğinize emin misiniz?"
    },
    labels: {
        username: "Kullanıcı Kimliği",
        password: "Şifre (Cihaz: {0})",
        remember: "Beni hatırla",
    },
    buttons: {
        next: "DEVAM",
        submit: "TAMAM",
        cancel: "Geri",
        signOut: "Oturumu Kapat",
        signOutDismiss: "HAYIR"
    },
}