<template>
  <v-menu :close-on-content-click="true" nudge-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="red" text v-bind="attrs" v-on="on" :disabled="inProgress">
        <i class="fas fa-fw fa-sign-out-alt right-spaced"></i>
        {{ $t("login.buttons.signOut") }}
      </v-btn>
    </template>

    <!-- Confirm Card -->
    <v-card color="grey lighten-4" class="confirm-card" :disabled="inProgress">
      <v-card-text> {{ $t("login.info.confirmSignOut") }} </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text :disabled="inProgress">{{
          $t("login.buttons.signOutDismiss")
        }}</v-btn>
        <v-btn text color="red" @click="confirmSignOut" :disabled="inProgress"
          ><i class="fas fa-fw fa-power-off right-spaced"></i>
          {{ $t("login.buttons.signOut") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "SignOutButton",
  data() {
    return {
      inProgress: false,
    };
  },
  methods: {
    ...mapActions(["signOut"]),
    confirmSignOut() {
      this.inProgress = true;
      this.signOut().finally(() => (window.location.href = "/"));
    },
  },
};
</script>

<style>
</style>