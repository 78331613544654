var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"toggle-card",class:[
  { 'in-progress': _vm.inProgress },
  {
    unreachablex:
      _vm.$props.device.state === 2 || _vm.$props.device.state === undefined,
  },
  { active: _vm.value },
],attrs:{"color":_vm.color,"elevation":_vm.elevation,"dark":_vm.dark,"disabled":_vm.inProgress || !_vm.$props.device,"disablex":_vm.inProgress ||
    !_vm.$props.device ||
    _vm.$props.device.state === 2 ||
    _vm.$props.device.state === undefined}},[_c('v-card-text',{staticClass:"text-left"},[_c('div',{staticClass:"text-body-2 d-flex flex-column"},[_c('strong',[_vm._v(_vm._s(_vm.device && _vm.device.name))]),_c('span',[_vm._v(_vm._s(_vm.device && _vm.roomName))])]),_c('iconic',{attrs:{"value":_vm.device.icon_name,"toggle":_vm.value,"color":_vm._iconColor}})],1),_c('v-switch',{attrs:{"color":_vm.switchColor,"inset":_vm.switchInset,"loading":_vm.inProgress && 'warning'},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.toggleLabel)}})]},proxy:true}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }