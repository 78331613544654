<template>
  <div class="gauge-dimmer">
    <div class="gauge-dimmer-button minus">
      <v-btn
        fab
        small
        :color="buttonColor"
        :elevation="buttonElevation"
        @click="decrease"
        v-holdable="decreaser"
        ><i class="fas fa-fw fa-minus"></i
      ></v-btn>
    </div>
    <div><Gauge ref="gauge" v-bind="$props" /></div>
    <div class="gauge-dimmer-button plus">
      <v-btn
        fab
        small
        :color="buttonColor"
        :elevation="buttonElevation"
        @click="increase"
        v-holdable="increaser"
        ><i class="fas fa-fw fa-plus"></i
      ></v-btn>
    </div>
  </div>
</template>

<script>
import Gauge from "./Gauge";

export default {
  components: {
    Gauge,
  },
  extends: Gauge,
  props: {
    buttonColor: {
      type: String,
      required: false,
      default: "grey lighten-3",
    },
    buttonElevation: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  methods: {
    increaser() {
      this.$emit("input", this.value + this.step);
    },
    decreaser() {
      this.$emit("input", this.value - this.step);
    },
  },
};
</script>

<style lang="scss">
.gauge-dimmer {
  display: inline-flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;

  & > div:not(.gauge-dimmer-button) {
    flex: 0.8;
  }

  & > div.gauge-dimmer-button {
    flex: 0.1;

    & > button {
      position: relative;
      top: -1em;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>