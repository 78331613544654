import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Login from '@/views/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Enterance",
    redirect: "/home"
  },
  {
    path: "/id/:id",
    name: "QuickLogin",
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/rooms',
    name: 'Rooms',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/gdpr',
    name: 'gdpr',
    component: ()=> import(/* webpackChunkName: "gdpr" */ "../views/gdpr.vue")
  },
  {
    path: '/kvkk',
    name: 'gdpr',
    component: ()=> import(/* webpackChunkName: "gdpr" */ "../views/gdpr.vue")
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
