export default {
    switchOn: "Açık",
    switchOff: "Kapalı",
    stateUp: "Yukarı",
    stateDown: "Aşağı",
    stateStop: "Dur",

    modal: {
        close: "Kapat",
        cancel: "İptal",
        confirm: "Tamam"
    },

    thermostat: {
        modeCooling: "Soğutma",
        modeHeating: "Isıtma",
        modeFan: "Fan",
        modeDry: "Kurutma",

        speedAuto: "Otomatik",
        speedMin: "Düşük",
        speedLow: "Yavaş",
        speedMedium: "Normal",
        speedHigh: "Hızlı"
    }
};