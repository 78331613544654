<template>
  <v-card :color="color" :elevation="elevation" :dark="dark" class="toggle-card" :disabled="inProgress || !$props.device"
    :disablex="inProgress ||
      !$props.device ||
      $props.device.state === 2 ||
      $props.device.state === undefined
      " :class="[
    { 'in-progress': inProgress },
    {
      unreachablex:
        $props.device.state === 2 || $props.device.state === undefined,
    },
    { active: value },
  ]">
    <v-card-text class="text-left">
      <div class="text-body-2 d-flex flex-column">
        <strong>{{ device && device.name }}</strong><span>{{ device && roomName }}</span>
      </div>

      <iconic :value="device.icon_name" :toggle="value" :color="_iconColor" />
    </v-card-text>
    <!-- <v-divider /> -->
    <v-switch v-model="value" :color="switchColor" :inset="switchInset" :loading="inProgress && 'warning'">
      <template v-slot:label><span v-html="toggleLabel"></span></template>
    </v-switch>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Iconic from "./molecular/Iconic.vue";

export default {
  name: "ToggleCard",
  components: {
    Iconic,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    passiveColor: {
      type: String,
      required: false,
      default: "",
    },
    activeColor: {
      type: String,
      required: false,
      default: "primary",
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
    switchColor: {
      type: String,
      required: false,
      default: "white",
    },
    switchInset: {
      type: Boolean,
      required: false,
      default: true,
    },
    switchLabelOn: {
      type: [String, Number, Boolean],
      required: false,
      default: "Açık",
    },
    switchLabelOff: {
      type: [String, Number, Boolean],
      required: false,
      default: "Kapalı",
    },
    iconColor: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    activeIcon: {
      type: String,
      required: false,
      default: null,
    },
    activeIconColor: {
      type: String,
      required: false,
      default: null,
    },
    progressColor: {
      type: String,
      required: false,
      default: "yellow",
    },
  },
  data() {
    return {
      inProgress: false,
    };
  },
  computed: {
    ...mapGetters(["rooms"]),
    value() {
      const isRelay =
        this.$props.device && this.$props.device.relay.toLowerCase() === "true";
      const state = this.$props.device && this.$props.device.state == 1;
      return isRelay ? !state : state;
    },
    color() {
      return !this.value
        ? this.passiveColor
        : this.activeColor || this.passiveColor;
    },
    _iconColor() {
      return this.value
        ? this.iconColor
        : this.activeIconColor || this.iconColor;
    },
    elevation() {
      return !this.value ? 3 : 10;
    },
    toggleLabel() {
      if (this.$props.device == undefined || this.$props.device.state == 2)
        return "<i class='fas fa-exclamation orange--text'></i>";
      else return this.value ? this.switchLabelOn : this.switchLabelOff;
    },
    roomName() {
      var name = "N/A";
      this.rooms.forEach((room) => {
        if (Number(room._id) == Number(this.$props.device.room_id))
          name = room.name;
      });
      return name;
    },
  },
  mounted() {
    const me = this;
    this.$el.addEventListener("click", ($event) => {
      $event.preventDefault();
      if (!me.inProgress) {
        me.inProgress = true;
        // KNX ise durumu gönder, değilse toggle gönder
        const _v = Number(me.$props.device.type) == 3 ? !this.value : null;
        me.$store.state.socketApi
          //.sendCommand(me.$props.device, !this.value, "switch")
          .sendCommand(me.$props.device, _v, "switch")
          .then(() => {
            console.log("[toggle_light]");
            me.$analytics.logEvent("action", { toggle: this.device.icon_name });
          })
          .finally(() => (me.inProgress = false));
      }
    });
  },
  methods: {
    ...mapActions(["getRoom"]),
  },
};
</script>

<style lang="scss">
@import "@/sass/touchable.scss";

.toggle-card {
  @extend .touchable-card;

  .v-input--switch {
    pointer-events: none;
    margin-top: 2px;
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 0.5rem;

    .v-input__slot {
      justify-content: flex-end;
      flex-direction: row-reverse;

      label {
        font-size: $message-font-size;
      }
    }
  }

  &.in-progress {
    transform: scale(0.9);
    box-shadow: 0 0px 3px 0 rgba(0, 0, 0, 0.5) !important;
  }

  &.primary {
    background: rgb(0, 104, 191);
    background: -moz-linear-gradient(90deg,
        rgba(0, 104, 191, 1) 0%,
        rgba(0, 151, 208, 1) 100%);
    background: -webkit-linear-gradient(90deg,
        rgba(0, 104, 191, 1) 0%,
        rgba(0, 151, 208, 1) 100%);
    background: linear-gradient(90deg,
        rgba(0, 104, 191, 1) 0%,
        rgba(0, 151, 208, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0068bf", endColorstr="#0097d0", GradientType=1);

    & * {
      color: #fff !important;
    }
  }
}

@media #{map-get($display-breakpoints, 'lg-and-up')} {
  .toggle-card i.toggle-card-icon {
    font-size: 250%;
  }
}
</style>