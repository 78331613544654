<template>
  <svg
    data-name="GaugeController"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 70"
  >
    <defs>
      <clipPath :id="`${gaugeId}_clippath`">
        <rect id="GaugeMask" width="100" height="59" />
      </clipPath>
      <clipPath :id="`${gaugeId}_dashpath`">
        <path
          d="M81.15,58.11a32.25,32.25,0,1,0-62.3,0l-4.91.73a37.33,37.33,0,1,1,72.12,0Z"
        />
        <path
          d="M92.47,49.77l4.35-.08V49.1l-4.36.15Zm-.16,4.68,4.34.38,0-.58-4.34-.32Zm-.19-9.34,4.31-.58L96.35,44,92,44.58Zm3.21-6.2L91.12,40l.12.5,4.23-1ZM93.76,34l-4.07,1.56.18.48L94,34.54Zm-2.11-4.7-3.87,2,.23.45,3.91-1.93ZM89,24.85l-3.61,2.4.27.43,3.67-2.34C89.25,25.17,89.14,25,89,24.85ZM86,20.71l-3.33,2.8.33.4,3.37-2.76Zm-3.5-3.77-3,3.14.39.38,3-3.13ZM75.87,17l.42.32L79,13.93l-.44-.35ZM72,14.38l.44.28L74.77,11l-.49-.29Zm-4.14-2.2.47.22,1.9-3.92-.51-.25Zm-4.33-1.73.49.16L65.48,6.5l-.55-.18ZM59,9.21l.51.1,1-4.24L60,5Zm-4.63-.74.53.06.48-4.33-.55-.07Zm-4.66-.23h.5l0-4.36h-.58Zm-5.17-4,.49,4.32.53,0-.47-4.34Zm-5.09.87,1,4.24.5-.1L40.06,5Zm-5,1.42L36,10.61l.5-.17-1.4-4.12Zm-4.76,2,1.88,3.93.48-.23L30.29,8.23ZM25.24,11l2.32,3.7.43-.27-2.25-3.74C25.58,10.74,25.42,10.85,25.24,11Zm-4.21,3,2.7,3.43.41-.33-2.66-3.45Zm-3.87,3.42,3.06,3.1.37-.37-3-3.13Zm-3.47,3.8,3.37,2.77.34-.42L14.06,20.7Zm-3,4.17,3.66,2.38.29-.45L11,24.82ZM8.09,29.78l3.9,2,.24-.47-3.88-2ZM6,34.52,10.13,36l.18-.5L6.25,34ZM4.53,39.45l4.23,1L8.9,40,4.67,38.91ZM8,44.58,3.66,44l-.09.58,4.32.56Zm-.31,9.51-4.34.34,0,.57,4.34-.4Zm-.12-4.34,0-.51L3.2,49.09l0,.59v.16l4.35.1Z"
        />
      </clipPath>

      <linearGradient
        :id="`${gaugeId}_lineargradient`"
        x1="12.67"
        y1="35.35"
        x2="87.33"
        y2="35.35"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          v-for="(stop, i) in gradient"
          :key="i"
          :offset="stop.offset"
          :stop-color="stop.color"
        />
      </linearGradient>
    </defs>
    <!-- #region:Guide -->

    <!-- Pointer Shadow -->
    <filter id="blur">
      <feGaussianBlur stdDeviation="0.5" />
    </filter>
    <circle
      v-if="debugMode"
      :cx="originX"
      :cy="originY"
      r="37"
      stroke="red"
      fill="transparent"
    />
    <circle
      id="GaugePin"
      :cx="originX"
      :cy="originY"
      r="1"
      fill="green"
      :opacity="debugMode ? 1 : 0"
    />
    <!-- #endregion:Guide -->

    <g>
      <text x="50%" y="80%" text-anchor="middle" stroke-width="0">
        {{ Number(gaugeValue).toFixed(decimals) }}{{ sign }}
      </text>

      <!-- Background Arc -->
      <path
        id="GaugeBg"
        :style="`fill: url(#${gaugeId}_lineargradient);`"
        d="M81.15,58.11a32.25,32.25,0,1,0-62.3,0l-4.91.73a37.33,37.33,0,1,1,72.12,0Z"
      />

      <!-- Color Dashes -->
      <g id="GaugeColorDashes">
        <path
          :style="`fill: url(#${gaugeId}_lineargradient);`"
          d="M92.47,49.77l4.35-.08V49.1l-4.36.15Zm-.16,4.68,4.34.38,0-.58-4.34-.32Zm-.19-9.34,4.31-.58L96.35,44,92,44.58Zm3.21-6.2L91.12,40l.12.5,4.23-1ZM93.76,34l-4.07,1.56.18.48L94,34.54Zm-2.11-4.7-3.87,2,.23.45,3.91-1.93ZM89,24.85l-3.61,2.4.27.43,3.67-2.34C89.25,25.17,89.14,25,89,24.85ZM86,20.71l-3.33,2.8.33.4,3.37-2.76Zm-3.5-3.77-3,3.14.39.38,3-3.13ZM75.87,17l.42.32L79,13.93l-.44-.35ZM72,14.38l.44.28L74.77,11l-.49-.29Zm-4.14-2.2.47.22,1.9-3.92-.51-.25Zm-4.33-1.73.49.16L65.48,6.5l-.55-.18ZM59,9.21l.51.1,1-4.24L60,5Zm-4.63-.74.53.06.48-4.33-.55-.07Zm-4.66-.23h.5l0-4.36h-.58Zm-5.17-4,.49,4.32.53,0-.47-4.34Zm-5.09.87,1,4.24.5-.1L40.06,5Zm-5,1.42L36,10.61l.5-.17-1.4-4.12Zm-4.76,2,1.88,3.93.48-.23L30.29,8.23ZM25.24,11l2.32,3.7.43-.27-2.25-3.74C25.58,10.74,25.42,10.85,25.24,11Zm-4.21,3,2.7,3.43.41-.33-2.66-3.45Zm-3.87,3.42,3.06,3.1.37-.37-3-3.13Zm-3.47,3.8,3.37,2.77.34-.42L14.06,20.7Zm-3,4.17,3.66,2.38.29-.45L11,24.82ZM8.09,29.78l3.9,2,.24-.47-3.88-2ZM6,34.52,10.13,36l.18-.5L6.25,34ZM4.53,39.45l4.23,1L8.9,40,4.67,38.91ZM8,44.58,3.66,44l-.09.58,4.32.56Zm-.31,9.51-4.34.34,0,.57,4.34-.4Zm-.12-4.34,0-.51L3.2,49.09l0,.59v.16l4.35.1Z"
        />
      </g>

      <!-- Masked Dashes -->
      <g id="GaugeGreyDashes" :style="`clip-path: url(#${gaugeId}_dashpath);`">
        <rect
          opacity="0.9"
          :width="originX"
          :height="originY"
          :fill="maskColor"
          :transform="`rotate(${angle},${originX},${originY})`"
        />
        <rect
          v-if="angle < 110 && angle > -90"
          opacity="0.9"
          :x="originX - 0.1"
          :width="originX"
          :height="originY * 1.5"
          :fill="maskColor"
          :transform="`rotate(${angle},${originX},${originY})`"
        />
      </g>
    </g>

    <circle
      id="GaugeOverlay"
      :cx="originX"
      :cy="originY"
      r="48"
      fill="red"
      opacity="0"
    />

    <circle
      id="GaugePointerBlur"
      v-if="pointerShadow"
      r="5"
      filter="url(#blur)"
      fill="black"
      :opacity="pointerShadowOpacity"
      :transform="`translate(15,${originY}) rotate(${angle},${originX - 15},0)`"
    />
    <circle
      id="GaugePointer"
      r="4"
      :fill="stepColor"
      :stroke-width="pointerBorderSize"
      :stroke="pointerBorderColor"
      :transform="`translate(15,${originY}) rotate(${angle},${originX - 15},0)`"
    />
  </svg>
</template>

<script>
const degMin = -10,
  degMax = 190;

var emitTimer;

export default {
  data() {
    return {
      gaugeId: 0,
      gaugeValue: 0,
      originX: 50,
      originY: 49.2,
      debugMode: false,
      isMovable: false,
      linearGradient: null,
    };
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    decimals: {
      type: Number,
      required: false,
      default: 0,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    maskColor: {
      type: String,
      required: false,
      default: "#cccccc",
    },
    pointerShadow: {
      type: Boolean,
      required: false,
      default: true,
    },
    pointerShadowOpacity: {
      type: Number,
      required: false,
      default: 0.5,
    },
    pointerBorderSize: {
      type: Number,
      required: false,
      default: 2.5,
    },
    pointerBorderColor: {
      type: String,
      required: false,
      default: "#ffffff",
    },
    pointerColor: {
      type: String,
      required: false,
      default: "#990000",
    },
    gradient: {
      type: [Object, Array],
      required: false,
      default() {
        return [
          { offset: 0, color: "#ffef26" },
          { offset: 1, color: "#e3312d" },
        ];
      },
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
    sign: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    ratio: {
      get() {
        const diff = 0 - this.min;
        return (this.gaugeValue + diff) / (this.max + diff);
      },
    },
    angle: {
      get() {
        const fixedValue =
          Number(this.gaugeValue.toFixed(this.decimals)) + (0 - this.min);
        const angleRange = degMax - degMin;
        const valueRange = this.max - this.min;
        const angleValue = angleRange / valueRange;
        return fixedValue * angleValue - (0 - degMin);
      },
      set(angle) {
        if (angle <= degMin) angle = degMin;
        else if (angle >= degMax) angle = degMax;

        const fixedAngle = angle + (0 - degMin);
        const angleRange = degMax - degMin;
        const valueRange = this.max - this.min;
        const angleValue = valueRange / angleRange;
        this.gaugeValue = fixedAngle * angleValue - (0 - this.min);
      },
    },
    gaugeController() {
      return this.$el;
    },
    gaugePointer() {
      return this.gaugeController.querySelector("#GaugePointer");
    },
    gaugeBg() {
      return this.gaugeController.querySelector("#GaugeBg");
    },
    gaugeGreyDashes() {
      return this.gaugeController.querySelector("#GaugeOverlay");
    },
    stepColor: {
      get() {
        return (
          (this.linearGradient &&
            this.linearGradient.getColor(this.ratio * 100).hex) ||
          "#000000"
        );
      },
    },
  },
  watch: {
    value(value) {
      if (value < this.min) value = this.min;
      else if (value > this.max) value = this.max;
      this.gaugeValue = Number(value.toFixed(this.decimals));
    },
    gaugeValue(value) {
      this.emit(value);
    },
    gradient(newValue) {
      this.parseGradient(newValue);
    },
  },
  created() {
    this.gaugeId = "gauge_" + Math.floor(Math.random() * 6000) + 1000;
  },
  mounted() {
    this.parseGradient(this.gradient);

    const me = this;
    me.gaugeValue = this.value;

    if (me.gaugeController && me.gaugePointer && me.gaugeBg) {
      me.gaugeController.addEventListener(
        "mousemove",
        me.touchMovePointer,
        false
      );
      me.gaugeController.addEventListener(
        "touchmove",
        (e) => {
          // translate to mouse event
          var clkEvt = document.createEvent("MouseEvent");
          clkEvt.initMouseEvent(
            "mousemove",
            true,
            true,
            window,
            e.detail,
            e.touches[0].screenX,
            e.touches[0].screenY,
            e.touches[0].clientX,
            e.touches[0].clientY,
            false,
            false,
            false,
            false,
            0,
            null
          );
          me.touchMovePointer(clkEvt);
        },
        me.$supportsPassive
      );

      me.gaugeController.addEventListener(
        "mouseup",
        () => (this.isMovable = false)
      );
      me.gaugeController.addEventListener(
        "touchend",
        () => (this.isMovable = false)
      );

      me.gaugePointer.addEventListener(
        "mousedown",
        () => (this.isMovable = true)
      );
      me.gaugePointer.addEventListener(
        "touchstart",
        () => (this.isMovable = true),
        me.$supportsPassive
      );
      me.gaugeController.addEventListener(
        "mouseleave",
        () => (this.isMovable = false)
      );

      const gaugeBg_click = (e) => this.setAngle(e.clientX, e.clientY);

      me.gaugeBg.addEventListener("click", gaugeBg_click);
      me.gaugeGreyDashes.addEventListener("click", gaugeBg_click);
    }
  },
  methods: {
    emit(value) {
      if (emitTimer) clearTimeout(emitTimer);
      if (value < this.min) value = this.min;
      else if (value > this.max) value = this.max;
      const me = this;
      emitTimer = setTimeout(() => {
        //me.$emit("input", Number(value.toFixed(me.decimals)));
        me.$emit("input", Number(value.toFixed(me.decimals)));
      }, 50);
    },
    setAngle(eX, eY) {
      eX = eX * 0.95;
      eY = eY * 0.95;
      const pin = this.$el.querySelector("#GaugePin");
      const cX =
        pin.getBoundingClientRect().x - pin.getBoundingClientRect().width;

      const cY =
        pin.getBoundingClientRect().y - pin.getBoundingClientRect().height;

      var angle = (Math.atan2(cY - eY, cX - eX) * 180) / Math.PI;
      if (angle < -90 && angle > -180) angle = 180 + (180 + angle);

      this.angle = angle;
    },
    touchMovePointer(e) {
      if (!this.isMovable) return e.clientX;
      else this.setAngle(e.clientX, e.clientY);
    },
    increase() {
      this.emit(this.value + 1);
    },
    decrease() {
      this.emit(this.value - 1);
    },
    parseGradient(gradient) {
      this.linearGradient = new this.$misc.color.LinearGradient(gradient);
    },
  },
};
</script>

<style lang="scss">
svg[data-name="GaugeController"] {
  touch-action: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  text {
    touch-action: none;
    font-size: 1em;
    font-weight: bold;
    letter-spacing: -0.07em;
  }
}
</style>