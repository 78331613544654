import { io } from "socket.io-client";
import store from "@/store";

// var self;
export default class extends EventTarget {
    constructor(authPayload, serviceUrl, isTest = false) {
        super();

        const me = this;
        // self = this;
        me.connected = false;
        console.log("socketAPI => TEST Mode", isTest);

        me.isTest = isTest;
        me.__socket = io(serviceUrl, { forceNew: true, closeOnBeforeunload: true });
        /*me.__pingTimer = setInterval(() => {
            try {
                if (me.__socket) {
                    console.log("pingAPI");
                    me.__socket.emit("ping", "");
                }
                else console.log("pingAPI:No-socket");

            } catch (error) {
                console.log("pingAPI:ERROR", error)
            }
        }, 4000);*/

        me.__socket.on("connect", () => {
            me.__socket.emit("auth", authPayload);

            // loadSettings
            this.loadSettings();

            // loadDatabase
            me.collectData();
        });
        me.__socket.on("disconnect", () => {
            if (me.__pingTimer) clearInterval(me.__pingTimer);
            store._vm.$hub.$emit("showMessage", { message: "message.connection.disconnected", options: { type: "warning" } });
            me.connected = false;
            console.log("server disconnected!");
        });

        me.__socket.on("authorized", () => {
            me.connected = true;

            console.log("authorized");

            // Request database daily
            setTimeout(() => {
                const cookieName = "dailyupdate";
                const dbRequested = store._vm.$cookies.get(cookieName);
                console.log(dbRequested);
                if (dbRequested) return;
                me.requestDatabaseUpdate().then(() => store._vm.$cookies.set(cookieName, true, "1d"));
            }, 1000);

            // Devices State Request
            const _gdsFail = () => {
                setTimeout(_gdsTry, 3 * 1000);
            };
            const _gdsTry = () => {
                me.getDeviceStatesByRoomId().catch(_gdsFail);
            };
            _gdsTry();

            store._vm.$hub.$emit("showMessage", { message: "message.connection.connected", options: { type: "success" } });
            store._vm.$hub.$emit("device-ready");
        });

        me.__socket.on("a6-connected", () => {
            console.log("A6 client connected!");

            me.__socket.on("deviceFeedback", me.deviceFeedback);
        });
        me.__socket.on("a6-disconnect", () => {
            console.log("A6 client disconnected!");
        });

        me.__socket.on("security-authorized", () => {
            console.log("Security authorized!");
            const me = this;

            // Security Ready
            store._vm.$hub.$emit("security-ready");

            // Security State Request
            const _gssFail = () => {
                setTimeout(_gssTry, 3 * 1000);
            };
            const _gssTry = () => {
                me.securityRequest().catch(_gssFail);
            };
            _gssTry();
        });

        me.__socket.on("ping", () => {
            console.log("ping");
        });
    }

    get socket() {
        return this.__socket;
    }

    collectData() {
        const me = this;
        // Database Request
        const _gdpSuccess = (data) => {
            store.state.deviceData = data;
            me.getDeviceStatesByRoomId(); // request favorite devices

            console.log("devivceData => ", data);

            // Request all rooms delayed
            /* if (store.state.deviceData.rooms) store.state.deviceData.rooms.forEach((room, n) => {
                setTimeout(() => me.getDeviceStatesByRoomId(room._id), n * 5 * 1000);
            }); */
        };
        const _gdpFail = (err) => {
            console.log("data-error", err);
            setTimeout(_gtpTry, 3 * 1000);
        };
        const _gtpTry = () => {
            me.getDataPackage()
                .then(_gdpSuccess)
                .catch(_gdpFail);
        };
        _gtpTry();
    }

    getDataPackage() {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket.emit("data-package", me.isTest, resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    sendCommand(device, value, command) {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket.emit("exec", { device, value, command }, resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    playScene(sceneId) {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket.emit("playScene", sceneId, resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    getDeviceState(device) {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket && me.__socket.emit("getDeviceState", device, resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    getDeviceStatesByRoomId(roomId = 0x00) {
        // 0x00 => Fovorite devices
        console.log("device-states-by-room", roomId);
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket && me.__socket.emit("getDeviceStatesByRoomId", roomId, resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    requestDatabaseUpdate() {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                if (!me.connected) throw new Error("no-connection");
                store._vm.$hub.$emit("showMessage", { message: "message.database.updating", options: { type: "warning" } });
                me.__socket.emit("requestDatabaseUpdate", resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    securityRequest() {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket.emit("security", "request", resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    securityMode(mode) {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket.emit("security", mode, resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    deviceFeedback(feedback) {
        if (!feedback) return;

        var skipSecurityFeedback;
        if (store && store.state && store.state.deviceData) {

            /* if (feedback.device_type !== "security") {
                console.log(`Feedback Dispatched! -> ${feedback.command}_${feedback.id}`);
                self.dispatchEvent(new CustomEvent(`${feedback.command}_${feedback.id}`, { detail: feedback }));
            } */

            if (feedback.device_type === "security" && skipSecurityFeedback !== feedback.mode) {
                skipSecurityFeedback = feedback.mode;
                store._vm.$set(store.state, "security", feedback);
            }
            // KNX Devices
            else if (feedback.device_type === "knx") {
                var knx = Buffer.from(feedback.knx);
                var dp = knx[4];

                //console.log("KNX Feedback", knx);

                store.state.deviceData.devices.filter((d) => (Number(d.type) == 3 && (
                    [
                        Number(d.binding_id),
                        Number(d.set_dp),
                        Number(d.set_feed_back_dp),
                        Number(d.temperature_dp),
                        Number(d.feed_back_dp),
                        Number(d.feed_back_fan_dp),
                        Number(d.feed_back_hc_dp),
                        Number(d.feed_back_nc_dp),
                        Number(d.feed_back_night_dp),
                        Number(d.hc_dp),
                        Number(d.kxn_thermo_on_off_fb),
                        Number(d.stop_dp)
                    ].indexOf(dp) >= 0
                ))).forEach((device) => {
                    if (device.knx_air_conditioner == "true") {
                        var _aircon_state = {}, _aircon_pow = -1;

                        if (knx[4] == Number(device.kxn_thermo_on_off_fb)) {
                            console.log("aircon power", knx[7]);
                            _aircon_pow = knx[7];
                        }
                        else if (knx[4] == Number(device.feed_back_hc_dp)) {
                            console.log("aircon mode", knx[7]);
                            switch(knx[7]){
                                case 0: _aircon_state.mode = "auto"; break;
                                case 1: _aircon_state.mode = "heating"; break;
                                case 3: _aircon_state.mode = "cooling"; break;
                                case 9: _aircon_state.mode = "fan"; break;
                                case 14: _aircon_state.mode = "dry"; break;
                                default: _aircon_state.mode = "--"; break;
                            }
                        }
                        else if (knx[4] == Number(device.feed_back_fan_dp)) {
                            console.log("aircon fan", knx[7]);
                            const _speed = [null, "min", "low", "medium", "high"];
                            _aircon_state.fan_speed = _speed[knx[7]];
                        }
                        else if (dp == device.set_feed_back_dp || dp == device.set_dp) {
                            var _aircon_target = ((knx[7] & 0xFF) << 8) | (knx[8] & 0xFF);
                            if (_aircon_target < 2048) _aircon_target = _aircon_target / 100;
                            else _aircon_target = (_aircon_target - 2048) / 50;

                            _aircon_state.targetTemperature = Math.round(_aircon_target);

                            console.log("aircon target temp", _aircon_target);
                        }
                        else if (dp == device.temperature_dp) {
                            var _aircon_current = ((knx[7] & 0xFF) << 8) | (knx[8] & 0xFF);
                            if (_aircon_current < 2048) _aircon_current = _aircon_current / 100;
                            else _aircon_current = (_aircon_current - 2048) / 50;

                            _aircon_state.currentTemperature = Math.round(_aircon_current);

                            console.log("aircon current temp", _aircon_current);
                        }
                        else console.log("aircon feedback N/A", dp, knx);

                        store._vm.$set(device, "info", { ...device.info, ..._aircon_state });
                        if (_aircon_pow >= 0) store._vm.$set(device, "state", _aircon_pow);
                    }
                    else if (device.icon_name === "thermostat") {
                        var _thermo_state = {};

                        if (dp == device.hc_dp || dp == device.feed_back_hc_dp) {
                            if (knx[6] == 0x81 || knx[6] == 0x41) _thermo_state.mode = "heating";
                            else if (knx[6] == 0x80 || knx[6] == 0x40) _thermo_state.mode = "cooling";
                            else return;

                            console.log("thermostat mode ", feedback.knx, _thermo_state.mode.toString(16));
                        }
                        else if (dp == device.set_feed_back_dp || dp == device.set_dp) {
                            var _target_temp = ((knx[7] & 0xFF) << 8) | (knx[8] & 0xFF);
                            if (_target_temp < 2048) _target_temp = _target_temp / 100;
                            else _target_temp = (_target_temp - 2048) / 50;

                            _thermo_state.targetTemperature = Math.round(_target_temp);

                            console.log("thermostat target temp", _target_temp);
                        }
                        else if (dp == device.temperature_dp) {
                            var _current_temp = ((knx[7] & 0xFF) << 8) | (knx[8] & 0xFF);
                            if (_current_temp < 2048) _current_temp = _current_temp / 100;
                            else _current_temp = (_current_temp - 2048) / 50;

                            _thermo_state.currentTemperature = Math.round(_current_temp);

                            console.log("thermostat current temp", _current_temp);
                        }
                        else if (knx && [0x80, 0x81, 0x40, 0x41].indexOf(knx[6]) >= 0) {
                            var _thermostat_state = (knx[6] == 0x81 || knx[6] == 0x41) ? 1 : 0;
                            store._vm.$set(device, "state", _thermostat_state);
                        }
                        else console.log("thermostat feedback N/A", dp, Buffer.from(knx).toString("hex"));

                        store._vm.$set(device, "info", { ...device.info, ..._thermo_state });
                        //store._vm.$set(device, "state", 1);
                    }

                    // KNX Shutter N Curtain
                    else if (["shutter", "curtain"].indexOf(device.icon_name) >= 0) {
                        console.log("curtain", feedback.knx);
                        var _curtain_state = "";
                        if (dp == Number(device.stop_dp)) _curtain_state = "stop";
                        else if (knx[6] == 0x80) _curtain_state = "open";
                        else if (knx[6] == 0x81) _curtain_state = "close";
                        store._vm.$set(device, "state", _curtain_state);
                    }

                    // KNX Dimmer
                    else if (device.icon_name === "dimmer") {
                        console.log("dimmer", feedback.knx);
                        var _dimmer_state = Math.round(knx[7] / 2.55);
                        store._vm.$set(device, "state", _dimmer_state);
                    }
                    // KNX ON-OFF
                    //else if (["on_off", "light"].indexOf(device.icon_name) >= 0) {
                    else if (knx && !!knx[6]) {
                        var _toggle_state = (knx[6] == 0x81 || knx[6] == 0x41) ? 1 : 0;
                        store._vm.$set(device, "state", _toggle_state);
                    }
                    else console.log("KNX Feedback N/A", dp, Buffer.from(knx).toString("hex"));
                })
            }
            /* else if (feedback.device_type === "knx") store.state.deviceData.devices.filter((d) => Number(d.type) == 3).forEach((device) => {
                if (Number(device.binding_id) == Number(feedback.id) || Number(device.feed_back_dp) == Number(feedback.id)) {

                    switch (feedback.command) {
                        case "onoff":
                            store._vm.$set(device, "state", Boolean(feedback.state));
                            break;

                        case "dimmer":
                            console.log("KNX Feedback =>", feedback, device);
                            store._vm.$set(device, "state", 30 + Math.round(70 * (feedback.state / 100)));
                            break;
                    }
                }
            }) */
            // NON-KNX Devices
            else store.state.deviceData.devices.filter((d) => Number(d.type) != 3).forEach((device) => {
                if (feedback.device_type === "gpio_relay" && device.icon_name === "relay_" + feedback.channel) {
                    store._vm.$set(device, "state", feedback.state);
                }
                else if (feedback.command === "thermostat" && device.binding_id == feedback.id) {
                    store._vm.$set(device, "info", feedback.thermostat);
                    store._vm.$set(device, "state", feedback.state);
                }
                else if (feedback.command === "dimmer" && device.binding_id == feedback.id) {
                    store._vm.$set(device, "state", feedback.state);
                }
                else if (feedback.command === "abus" && feedback.device_type === "relay" && Number(device.address) == Number(feedback.address) && Number(device.channel) == Number(feedback.channel)) {
                    store._vm.$set(device, "state", feedback.state);
                }
                else if (feedback.command === "abus" && feedback.device_type === "probe" && Number(device.address) == Number(feedback.address) && Number(device.channel) == Number(feedback.channel)) {
                    const _device_info = device.info || {};
                    store._vm.$set(device, "info", { ..._device_info, ...feedback.thermostat });
                    store._vm.$set(device, "state", feedback.state);
                    console.log("Feedback Probe =>", feedback);
                }
                else if (Number(device.binding_id) == Number(feedback.id)) {
                    store._vm.$set(device, "state", feedback.state);
                }
            });

            const _fedbackData = {};
            _fedbackData[feedback.command] = feedback.device_type;

            store._vm.$analytics.logEvent("feedback", _fedbackData);

            store._vm.$root.$forceUpdate();
        }
    }

    destroy() {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket.close();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }

    registerGCM(token) {
        this.__socket.emit("registerGCM", token);
    }

    saveSettings(value) {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                if (!me.connected) throw new Error("no-connection");
                me.__socket.emit("settings", value, resolve);
            } catch (error) {
                reject(error);
            }
        });
    }

    loadSettings() {
        const me = this;
        return new Promise((resolve, reject) => {
            try {
                me.__socket.emit("settings", "load", (error, data) => {
                    if (error) console.log(error);
                    else {
                        store._vm.$set(store.state, "settings", data);
                        // store._vm.$hub.$emit("settings-loaded", data);
                        resolve(data);
                    }
                });
            } catch (error) {
                reject(error);
            }
        });
    }
}