<template>
  <div id="WidgetsWrapper">
    <WeatherWidget v-if="true" :options="widgetSettings"></WeatherWidget>
    <TimeWidget v-if="false"></TimeWidget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WeatherWidget from "./widgets/WeatherWidget";
import TimeWidget from "./widgets/TimeWidget";

export default {
  components: {
    WeatherWidget,
    TimeWidget,
  },
  data() {
    return {
      defaults: {
        weatherLoation: "TR",
        weatherUnits: "metric",
      },
    };
  },
  computed: {
    ...mapGetters(["user", "userSettings"]),
    widgetSettings() {
      return (
        (this.user && this.userSettings && this.userSettings) || this.defaults
      );
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables.scss";

#WidgetsWrapper {
  width: 100%;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  #WidgetsWrapper {
    padding-top: 1rem;
  }
}
</style>