<template>
  <v-dialog fullscreen hide-overlay v-model="isOffline">
    <div id="ConnectionWarning">
      <div>
        <div>
          <img src="@/assets/icons/no-connection.svg" alt="no connection" />
        </div>
        <div>
          <h4 class="text-h4">Bir şeyler eksik!</h4>
          <p class="text-subtitle-1">
            Lütfen İnternet bağlantınızı kontrol edin.
          </p>
          <v-btn
            large
            dark
            elevation="0"
            color="blue"
            @click="connectionHandler"
            :disabled="trying"
          >
            <i class="fas fa-fw fa-sync-alt" :class="{ 'fa-spin': trying }"></i>
            TEKRAR DENE
          </v-btn>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "OfflineModal",
  data() {
    return {
      isOffline: false,
      trying: false,
    };
  },
  mounted() {
    window.addEventListener("online", this.connectionHandler);
    window.addEventListener("offline", this.connectionHandler);
    this.connectionHandler();
  },
  methods: {
    ...mapActions(["connectionCheck"]),
    connectionHandler() {
      this.trying = true;
      if (navigator.onLine) {
        this.connectionCheck()
          .then((online) => (this.isOffline = !online))
          .catch(() => (this.isOffline = true))
          .finally(() => (this.trying = false));
      } else {
        // handle offline status
        console.log("offline");
        this.isOffline = true;
        setTimeout(() => (this.trying = false), 1000);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables.scss";

#ConnectionWarning {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background: #eee;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 70%;
    justify-self: center;
    margin: 0 auto;

    & > div:last-of-type {
      padding-left: 2rem;
    }

    img {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    i {
      margin-right: 0.5rem;
    }

    h4,
    p {
      color: rgba(0, 0, 0, 0.7);
      text-shadow: 1px 1px 0 #fff;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} and (orientation: portrait) {
  #ConnectionWarning {
    & > div {
      flex-direction: column;
      max-width: 100%;

      & > div:last-of-type {
        padding: 0;
        text-align: center;
      }

      img {
        width: 50vw;
        margin: 10vh 0 2vh 0;
      }
      .v-btn {
        margin-top: 2vh;
      }
    }
  }
}
</style>