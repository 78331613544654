<template>
  <svg id="VerticalDimmer"
       xmlns="http://www.w3.org/2000/svg"
       version="1.1"
       viewBox="0 0 20 120"
       data-name="DimmerController"
       @mousemove="__move"
       @touchmove="__touchmove"
       @mouseup="isMovable = false"
       @touchend="isMovable = false"
       @mouseleave="isMovable = false">

    <!-- This definations moved into /public/index.html for globally use -->
    <defs>
      <clipPath id="MaskPath">
        <path id="Dashes"
              class="cls-1"
              transform="translate(2,0)"
              d="M16.45,106.5h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Zm0-4h-3v-1h3Z" />
        <rect id="Guide"
              x="6"
              y="10"
              width="4"
              height="100"
              fill="red"
              rx="2"
              ry="2" />
      </clipPath>

      <linearGradient id="MeterGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="120"
                      gradientUnits="userSpaceOnUse">
        <stop v-for="(stop, i) in gradient"
              :key="i"
              :offset="stop.offset"
              :stop-color="stop.color" />
      </linearGradient>
    </defs>

    <!-- Pointer Shadow -->
    <filter id="blur">
      <feGaussianBlur stdDeviation="0.7" />
    </filter>

    <rect id="MeterGuide"
          x="0"
          y="0"
          width="20"
          height="120"
          class="meter-guide" />
    <g id="MeterMask"
       class="meter-mask">
      <rect x="0"
            y="10"
            width="20"
            height="100"
            :fill="maskColor"
            :transform="`translate(0,${maskTranslate})`" />
    </g>

    <g id="MeterOverlay">
      <rect x="6"
            y="10"
            width="13"
            height="100"
            fill="transparent"
            opacity="0" />
    </g>

    <circle id="MeterPinBlur"
            v-if="pointerShadow"
            cx="7"
            cy="106"
            r="6"
            filter="url(#blur)"
            fill="black"
            :opacity="pointerShadowOpacity"
            :transform="`translate(1,${maskTranslate + 3.5})`" />
    <circle id="MeterPin"
            cx="7"
            cy="106"
            r="5"
            :fill="stepColor"
            :stroke="pointerBorderColor"
            :stroke-width="pointerBorderSize"
            :transform="`translate(1,${maskTranslate + 3.5})`"
            @mousedown="isMovable = true"
            @touchstart="isMovable = true" />
  </svg>
</template>

<script>
export default {
  data() {
    return {
      svgInit: null,
      isMovable: false,
      linearGradient: null,
      x_ratio: 0,
    };
  },
  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    decimals: {
      type: Number,
      required: false,
      default: 0,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    maskColor: {
      type: String,
      required: false,
      default: "#cccccc",
    },
    pointerShadow: {
      type: Boolean,
      required: false,
      default: true,
    },
    pointerShadowOpacity: {
      type: Number,
      required: false,
      default: 0.5,
    },
    pointerBorderSize: {
      type: Number,
      required: false,
      default: 2.5,
    },
    pointerBorderColor: {
      type: String,
      required: false,
      default: "#ffffff",
    },
    pointerColor: {
      type: String,
      required: false,
      default: "#990000",
    },
    gradient: {
      type: [Object, Array],
      required: false,
      default() {
        return [
          { offset: 0, color: "#ffea61" },
          { offset: 1, color: "#ffffb7" },
        ];
      },
    },
    step: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    maskTranslate() {
      let v = 0 - this.ratio * 100;
      if (v < -100) v = -100;
      else if (v > 0) v = 0;
      return v;
    },
    ratio: {
      get() {
        if (isNaN(this.value)) return 0;
        else return (this.value + (0 - this.min)) / (this.max - this.min);
      },
      set(ratio) {
        const diff = 0 - this.min;
        const range = this.max - this.min;
        let value = range * (1 - ratio) - diff;
        if (value < this.min) value = this.min;
        else if (value > this.max) value = this.max;
        this.emit(value);
      },
    },
    stepColor: {
      get() {
        let _value;

        if (this.linearGradient)
          _value = (
            this.linearGradient.getColor(100 - this.ratio * 100).hex ||
            "#000000"
          );
        else _value = this.pointerColor;

        this.$emit("stepColor", _value);
        return _value;
      },
    },
  },
  watch: {
    value(newValue) {
      if (newValue < this.min) this.emit(this.min);
      else if (newValue > this.max) this.emit(this.max);
    },
    gradient(newValue) {
      this.parseGradient(newValue);
    },
  },
  mounted() {
    this.parseGradient(this.gradient);

    const me = this;
    this.svgInit = setInterval(() => {
      let MeterOverlay = me.$el.querySelector("#MeterOverlay");
      if (MeterOverlay)
        MeterOverlay.addEventListener("click", me.__move, me.$supportsPassive);

      let MeterPin = me.$el.querySelector("#MeterPin");
      if (MeterPin) {
        MeterPin.addEventListener(
          "mousedown",
          () => (me.isMovable = true),
          me.$supportsPassive
        );
        MeterPin.addEventListener(
          "touchstart",
          () => (me.isMovable = true),
          me.$supportsPassive
        );
      }
      clearInterval(this.svgInit);
    }, 100);
  },
  methods: {
    parseGradient(gradient) {
      this.linearGradient = new this.$misc.color.LinearGradient(gradient);
    },
    emit(value) {
      this.$emit("input", value);
    },
    increase() {
      this.emit(this.value + this.step);
    },
    decrease() {
      this.emit(this.value - this.step);
    },
    __move(e) {
      if (e.type !== "click" && !this.isMovable) return;
      const Dashes = this.$el.querySelector("#Dashes");
      const rect = Dashes.getBoundingClientRect();
      let ratio = (e.clientY - rect.y) / rect.height;
      if (ratio > 1) ratio = 1;
      else if (ratio < 0) ratio = 0;
      this.ratio = ratio;
    },
    __touchmove(e) {
      // translate to mouse event
      var clkEvt = document.createEvent("MouseEvent");
      clkEvt.initMouseEvent(
        "mousemove",
        true,
        true,
        window,
        e.detail,
        e.touches[0].screenX,
        e.touches[0].screenY,
        e.touches[0].clientX,
        e.touches[0].clientY,
        false,
        false,
        false,
        false,
        0,
        null
      );
      this.__move(clkEvt);
    },
  },
};
</script>

<style lang="scss">
svg {
  touch-action: none;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  .cls-1 {
    fill: #1d1d1b;
  }

  #MeterMask,
  .meter-mask {
    clip-path: url(#MaskPath);
  }

  #MeterGuide,
  .meter-guide {
    clip-path: url(#MaskPath);
    fill: url(#MeterGradient);
  }
}
</style>