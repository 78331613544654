<template>
  <v-card class="thermostat-card"
          color="lighten-2"
          :elevation="elevation"
          :disablex="
            inProgress ||
            !device ||
            !device.info ||
            device.state == 2 ||
            $props.device.state === undefined
          "
          :disabled="inProgress || !device"
          :class="[
            info && $props.device.state && info.mode,
            { 'in-progress': inProgress },
            {
              unreachablex:
                $props.device.state === 2 || $props.device.state === undefined,
            },
          ]">
    <v-card-text style="flex: 1"
                 @click="toggleThermostat">
      <div class="text-body-2">
        <strong>{{ device && device.name }}</strong><br />{{ device && roomName }}
      </div>

      <v-row dense>
        <v-col cols="7">
          <div class="headline">
            <i :class="thermometer"></i>{{ info.currentTemperature }}&deg;
          </div>
          {{ info.mode }}
        </v-col>
        <v-col cols="5"
               class="text-right">
          <div class="mode-info">
            <i class="mr-1"
               :class="modeIcon"></i>{{ info.targetTemperature }}&deg;
          </div>
          {{ info.fan_speed }}
          <!-- <div v-if="ecomode">
                <small class="green--text text--lighten-2"
                  ><i class="fas fa-leaf"></i>
                  <span class="hidden-sm-and-down">eco</span></small
                >
              </div> -->
        </v-col>
      </v-row>
    </v-card-text>

    <v-divider></v-divider>
    <v-btn text
           @click="dialog = true">
      <v-icon v-if="!inProgress"
              small>fas fa-ellipsis-h</v-icon>
      <v-icon v-if="inProgress"
              small>fas fa-cog fa-spin</v-icon>
    </v-btn>

    <!-- #region AirConditioner Modal -->
    <v-dialog v-model="dialog"
              :persistent="inProgress"
              width="400">
      <v-card class="airconditioner-modal"
              :disabled="inProgress">
        <v-card-title class="headline grey lighten-2">
          <div>{{ device && device.name }}</div>
          <div>
            <strong>{{ info && info.currentTemperature }}&deg;</strong>
          </div>
        </v-card-title>

        <v-card-text>
          <v-row no-gutters
                 class="modes mt-5">
            <v-col :class="{ active: thermostat.mode == 'heating' }">
              <v-sheet v-ripple
                       rounded
                       class="heating"
                       :outlined="thermostat.mode !== 'heating'"
                       @click="thermostat.mode = 'heating'">
                <i class="ma-4 fa-3x"
                   :class="modes.heating.icon"></i>
              </v-sheet>
              <label>{{ $t("touchable.thermostat.modeHeating") }}</label>
            </v-col>
            <v-col :class="{ active: thermostat.mode == 'cooling' }">
              <v-sheet v-ripple
                       rounded
                       class="cooling"
                       :outlined="thermostat.mode !== 'cooling'"
                       @click="thermostat.mode = 'cooling'">
                <i class="ma-4 fa-3x"
                   :class="modes.cooling.icon"></i>
              </v-sheet>
              <label>{{ $t("touchable.thermostat.modeCooling") }}</label>
            </v-col>
            <v-col :class="{ active: thermostat.mode == 'fan' }">
              <v-sheet v-ripple
                       rounded
                       class="fan"
                       :outlined="thermostat.mode !== 'fan'"
                       @click="thermostat.mode = 'fan'">
                <i class="ma-4 fa-3x"
                   :class="modes.fan.icon"></i>
              </v-sheet>
              <label>{{ $t("touchable.thermostat.modeFan") }}</label>
            </v-col>
            <v-col :class="{ active: thermostat.mode == 'dry' }">
              <v-sheet v-ripple
                       rounded
                       class="dry"
                       :outlined="thermostat.mode !== 'dry'"
                       @click="thermostat.mode = 'dry'">
                <i class="ma-4 fa-3x"
                   :class="modes.dry.icon"></i>
              </v-sheet>
              <label>{{ $t("touchable.thermostat.modeDry") }}</label>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <gauge-dimmer :min="5"
                        :max="35"
                        :gradient="modeGradient"
                        sign="°"
                        v-model="thermostat.temp" />
        </v-card-text>

        <v-card-text class="text-center">
          <v-row align="center">
            <v-col></v-col>
            <v-col>
              <v-btn fab
                     :elevation="!thermostat.on ? 5 : 0"
                     :color="!thermostat.on ? 'white' : 'green lighten-3'"
                     :dark="thermostat.on"
                     @click="thermostat.on = !thermostat.on">
                <i class="fas fa-fw fa-power-off"></i>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn smal
                     text
                     elevation="0"
                     :color="ecomode ? 'green lighten-1' : 'grey lighten-2'"
                     class="eco-mode pa-2"
                     @click="setEcoMode">
                <i class="ma-1 fas fa-fw fa-2x fa-leaf"></i>
                <div>eco</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <v-row class="modes mt-5">
            <v-col :class="{ active: thermostat.speed == 'min' && thermostat.on }">
              <v-sheet v-ripple
                       outlined
                       rounded
                       min
                       :color="thermostat.speed == 'min' ? 'blue' : 'gray'"
                       @click="thermostat.speed = 'min'">
                <i class="ma-3 fas fa-fw fa-2x fa-fan"></i>
              </v-sheet>
              <label>{{ $t("touchable.thermostat.speedMin") }}</label>
            </v-col>
            <v-col :class="{ active: thermostat.speed == 'low' && thermostat.on }">
              <v-sheet v-ripple
                       outlined
                       rounded
                       low
                       :color="thermostat.speed == 'low' ? 'blue' : 'gray'"
                       @click="thermostat.speed = 'low'">
                <i class="ma-3 fas fa-fw fa-2x fa-fan"></i>
              </v-sheet>
              <label>{{ $t("touchable.thermostat.speedLow") }}</label>
            </v-col>
            <v-col :class="{ active: thermostat.speed == 'medium' && thermostat.on }">
              <v-sheet v-ripple
                       outlined
                       rounded
                       medium
                       :color="thermostat.speed == 'medium' ? 'blue' : 'gray'"
                       @click="thermostat.speed = 'medium'">
                <i class="ma-3 fas fa-fw fa-2x fa-fan"></i>
              </v-sheet>
              <label>{{ $t("touchable.thermostat.speedMedium") }}</label>
            </v-col>
            <v-col :class="{ active: thermostat.speed == 'high' && thermostat.on }">
              <v-sheet v-ripple
                       outlined
                       rounded
                       high
                       :color="thermostat.speed == 'high' ? 'blue' : 'gray'"
                       @click="thermostat.speed = 'high'">
                <i class="ma-3 fas fa-fw fa-2x fa-fan"></i>
              </v-sheet>
              <label>{{ $t("touchable.thermostat.speedHigh") }}</label>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row dense>
            <v-col>
              <v-btn color="red"
                     text
                     block
                     @click="dialog = false">
                {{ $t("touchable.modal.cancel") }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="primary"
                     text
                     block
                     @click="setThermostat">
                {{ $t("touchable.modal.confirm") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- #endregion -->
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import GaugeDimmer from "./molecular/GaugeDimmer";

export default {
  components: {
    GaugeDimmer,
  },
  data() {
    return {
      dialog: false,
      temperature: 18,
      inProgress: false,
      thermostat: {
        mode: "heating",
        speed: "min",
        temp: 22,
        on: true,
      },
    };
  },
  props: {
    device: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["rooms"]),
    ...mapState(["socketApi"]),
    modes: {
      get() {
        return {
          heating: {
            icon: "fas fa-fw fa-sun",
            gradient: [
              { offset: 0, color: "#FFE72B" },
              { offset: 1, color: "#EE092D" },
            ],
          },
          cooling: {
            icon: "fas fa-fw fa-snowflake",
            gradient: [
              { offset: 0, color: "#E1F5FE" },
              { offset: 1, color: "#03A9FA" },
            ],
          },
          fan: {
            icon: "fas fa-fw fa-fan",
            gradient: [
              { offset: 0, color: "#CBF6DB" },
              { offset: 1, color: "#9BC472" },
            ],
          },
          dry: {
            icon: "fas fa-fw fa-tint",
            gradient: [
              { offset: 0, color: "#cca244" },
              { offset: 1, color: "#885520" },
            ],
          }
        };
      },
    },
    ecomode: {
      get() {
        return this.thermostat.temp == 24 && this.thermostat.on;
      },
    },
    modeGradient: {
      get() {
        return this.modes[this.thermostat.mode]?.gradient;
      },
    },
    thermometer: {
      get() {
        if (!this.info || isNaN(this.info.currentTemperature))
          return "fas fa-fw fa-exclamation";
        else if (this.info.currentTemperature >= 35)
          return "fas fa-fw fa-thermometer-full";
        else if (this.info.currentTemperature >= 30)
          return "fas fa-fw fa-thermometer-three-quarters";
        else if (this.info.currentTemperature >= 10)
          return "fas fa-fw fa-thermometer-half";
        else if (this.info.currentTemperature > 5)
          return "fas fa-fw fa-thermometer-quarter";
        else return "fas fa-fw fa-thermometer-empty";
      },
    },
    modeIcon: {
      get() {
        if (
          !this.info | !this.info.mode ||
          !/^(cooling|heating|fan|dry)$/.test(this.info.mode)
        )
          return "fas fa-times";
        else return this.modes[this.info.mode].icon;
      },
    },
    elevation: {
      get() {
        return this.$props.device.state == 1 ? 10 : 3;
      },
    },
    info: {
      get() {
        var info = {
          currentTemperature: "--",
          fan_speed: "--",
          mode: "--",
          protectMode: false,
          sleepMode: false,
          targetTemperature: "--",
          timerHour: 0,
          timerState: null,
        };
        if (this.$props.device && this.$props.device.info) {
          info = Object.assign(info, this.$props.device.info);
        }

        if (
          !this.$props.device ||
          this.$props.device.state == undefined ||
          this.$props.device.state == 2
        )
          info.mode = "--";
        else if (!this.$props.device && !this.$props.device.state)
          info.mode = "OFF";
        return info;
      },
    },
    roomName() {
      var name = "N/A";
      this.rooms.forEach((room) => {
        if (Number(room._id) == Number(this.$props.device.room_id))
          name = room.name;
      });
      return name;
    },
  },
  watch: {
    dialog(dialog) {
      if (dialog && this.$props.device && !isNaN(this.info.targetTemperature)) {
        this.thermostat.mode = this.info.mode;
        this.thermostat.temp = this.info.targetTemperature;
        this.thermostat.speed = this.info.fan_speed;
        this.thermostat.on = this.$props.device.state == 1;
      }
    },
  },
  mounted() {
    setTimeout(this.requestDeviceState, 3000);
  },
  methods: {
    setEcoMode() {
      this.thermostat.temp = 24;
      this.thermostat.on = true;
    },
    setThermostat() {
      console.log("airconditioner", (this.inProgress || !this.$props.device || (Number(this.$props.device.type) != 3 && !this.$props.device.info)))
      if (this.inProgress || !this.$props.device || (Number(this.$props.device.type) != 3 && !this.$props.device.info))
        return;
      const me = this;
      me.inProgress = true;

      me.$store.state.socketApi
        .sendCommand(
          me.$props.device,
          Object.assign({ ...me.$props.device.info }, {
            state: me.thermostat.on,
            mode: me.thermostat.mode,
            fan_speed: me.thermostat.speed,
            targetTemperature: me.thermostat.temp,
          }),
          "thermostat"
        )
        .then(() => (me.dialog = false))
        .catch(() => {
          me.$notify({
            type: "error",
            title: "Cihaza erişilemedi!",
            text: "Bağlantınızı kontrol edin ve tekrar deneyin.",
          });
          me.$analytics.logEvent("action", { thermostat: "set" })
        })
        .finally(() => (me.inProgress = false));
    },
    toggleThermostat() {
      if (this.inProgress || !this.device || !this.$store.state.socketApi)
        return;
      else {
        const me = this;
        me.inProgress = true;
        me.$store.state.socketApi
          .sendCommand(me.$props.device, null, "switch")
          .then(() => me.$analytics.logEvent("action", { thermostat: "toggle" }))
          .finally(() => (me.inProgress = false));
      }

      /* if (
        this.inProgress ||
        !this.$props.device ||
        !this.$props.device.info ||
        isNaN(this.info.targetTemperature)
      )
        return;

      this.thermostat.mode = this.info.mode;
      this.thermostat.temp = this.info.targetTemperature;
      this.thermostat.speed = this.info.fan_speed;
      this.thermostat.on = !this.$props.device.state;

      this.setThermostat(); */
    },
    requestDeviceState() {
      console.log(
        "thermostat request",
        this.socketApi && this.socketApi.connected
      );
      if (this.socketApi && this.socketApi.connected)
        this.socketApi
          .getDeviceState(this.device)
          .catch(this.requestDeviceState);
      else setTimeout(this.requestDeviceState, 10 * 1000);
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/touchable.scss";

.thermostat--mode--heating--gradient {
  color: #fff;
  background: rgb(255, 231, 43);
  background: -moz-radial-gradient(circle,
      rgba(255, 231, 43, 1) 0%,
      rgba(250, 171, 44, 1) 100%);
  background: -webkit-radial-gradient(circle,
      rgba(255, 231, 43, 1) 0%,
      rgba(250, 171, 44, 1) 100%);
  background: radial-gradient(circle,
      rgba(255, 231, 43, 1) 0%,
      rgba(250, 171, 44, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffe72b", endColorstr="#faab2c", GradientType=1);
}

.thermostat--mode--cooling--gradient {
  color: #fff;
  background: rgb(225, 245, 254);
  background: -moz-radial-gradient(circle,
      rgba(225, 245, 254, 1) 0%,
      rgba(145, 218, 253, 1) 100%);
  background: -webkit-radial-gradient(circle,
      rgba(225, 245, 254, 1) 0%,
      rgba(145, 218, 253, 1) 100%);
  background: radial-gradient(circle,
      rgba(225, 245, 254, 1) 0%,
      rgba(145, 218, 253, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e1f5fe", endColorstr="#91dafd", GradientType=1);
}

.thermostat--mode--fan--gradient {
  color: #fff;
  background: rgb(203, 246, 219);
  background: -moz-radial-gradient(circle,
      rgba(203, 246, 219, 1) 0%,
      rgba(185, 228, 180, 1) 100%);
  background: -webkit-radial-gradient(circle,
      rgba(203, 246, 219, 1) 0%,
      rgba(185, 228, 180, 1) 100%);
  background: radial-gradient(circle,
      rgba(203, 246, 219, 1) 0%,
      rgba(185, 228, 180, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cbf6db", endColorstr="#b9e4b4", GradientType=1);
}

.thermostat--mode--dry--gradient {
  color: #fff;
  background: rgb(204, 162, 68);
  background: -moz-radial-gradient(circle,
      rgba(204, 162, 68, 1) 0%,
      rgba(136, 85, 32, 1) 100%);
  background: -webkit-radial-gradient(circle,
      rgba(204, 162, 68, 1) 0%,
      rgba(136, 85, 32, 1) 100%);
  background: radial-gradient(circle,
      rgba(204, 162, 68, 1) 0%,
      rgba(136, 85, 32, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cca244", endColorstr="#885520", GradientType=1);
}

.fan-speed-rotation-auto {
  animation: rotate ease-in-out 3s infinite;
}

.fan-speed-rotation-min {
  animation: rotate linear 4s infinite;
}

.fan-speed-rotation-low {
  animation: rotate linear 3s infinite;
}

.fan-speed-rotation-medium {
  animation: rotate linear 2s infinite;
}

.fan-speed-rotation-high {
  animation: rotate linear 1s infinite;
}

.thermostat-card {
  @extend .touchable-card;
  flex-direction: column;

  .v-card__text {
    display: flex;
    flex-direction: column;

    &>div.row {
      flex: 1;
      align-items: flex-end;
    }

    & .mode-info {
      font-size: 1rem;
    }
  }
}

.thermostat-card.heating>.v-card__text {
  @extend .thermostat--mode--heating--gradient;
}

.thermostat-card.cooling>.v-card__text {
  @extend .thermostat--mode--cooling--gradient;
}

.thermostat-card.fan>.v-card__text {
  @extend .thermostat--mode--fan--gradient;
}

.thermostat-card.dry>.v-card__text {
  @extend .thermostat--mode--dry--gradient;
}

.airconditioner-modal {
  div.v-card__title {
    display: flex;
    flex-direction: row;

    &> :first-child {
      flex: 1;
    }
  }

  .modes .col {
    text-align: center;

    .v-sheet {
      display: inline-flex;
      background: #f5f5f5;
    }

    &>label {
      display: block;
      font-size: 90%;
      font-weight: normal;
      margin-top: 0.5rem;
    }

    .v-sheet {
      .fa-fw {
        width: auto;
      }

      .fa-3x {
        font-size: 2rem;
      }
    }

    &:not(.active) {
      opacity: 0.7;
    }

    .heating {
      color: #f5d54a;
      border-color: #f5d54a;
    }

    .cooling {
      color: #03a9fa;
      border-color: #03a9fa;
    }

    .fan {
      color: #9bc472;
      border-color: #9bc472;
    }

    .dry {
      color: #00c3f9;
      border-color: #00c3f9;
    }
  }

  .modes .col.active {

    .v-sheet.heating,
    .v-sheet.cooling,
    .v-sheet.fan {
      box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);

      i {
        animation: rotate linear 120s infinite;
      }
    }

    .v-sheet.heating {
      @extend .thermostat--mode--heating--gradient;
    }

    .v-sheet.cooling {
      @extend .thermostat--mode--cooling--gradient;
    }

    .v-sheet.fan {
      @extend .thermostat--mode--fan--gradient;
    }

    .v-sheet.dry {
      @extend .thermostat--mode--dry--gradient;
    }

    .v-sheet>i {
      color: #fff;
      animation-delay: 0.2s;
    }

    .v-sheet[auto]>i {
      @extend .fan-speed-rotation-auto;
    }

    .v-sheet[min]>i {
      @extend .fan-speed-rotation-min;
    }

    .v-sheet[low]>i {
      @extend .fan-speed-rotation-low;
    }

    .v-sheet[medium]>i {
      @extend .fan-speed-rotation-medium;
    }

    .v-sheet[high]>i {
      @extend .fan-speed-rotation-high;
    }
  }

  .eco-mode {
    height: auto !important;

    .v-btn__content {
      display: flex;
      flex-direction: column;
      text-transform: lowercase;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>