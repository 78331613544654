export default {
    //
    weatherWidget: {
        header: "Weather Widget",
        selectCity: "Which city do you live in?"
    },

    setLanguage: "Select your language",

    other: {
        header: "Other Settings",
        deviceId: "Device ID",
        updateDatabase: "Reload Database"
    },

    modal: {
        title: "Settings",
        dismiss: "Close"
    }
};