export default {
    info: {
        connectionReminder: "Note that your controller must be connected to the Internet before you can sign in.",
        helpText: "If you are having trouble sign in, check out the help topics…",
        confirmSignOut: "Are you sure?"
    },
    labels: {
        username: "User ID",
        password: "Password",
        remember: "Remember me",
    },
    buttons: {
        next: "NEXT",
        submit: "SIGN IN",
        cancel: "Cancel",
        signOut: "Sign Out",
        signOutDismiss: "NO"
    },
};