export default {
    install(Vue) {

        Vue.mixin({
            created() {
                const vm = this;
                vm.$rules = {
                    required(value) {
                        if (typeof value === "boolean") return value || vm.$t("message.error-required");
                        else return (value && value.length > 0) || vm.$t("message.error-required");
                    },
                    deviceId(value) {
                        return (value && !isNaN(value) && /^\d{1,16}$/.test(value)) || vm.$t("message.error-deviceid");
                    },
                    email(value) {
                        return /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) || "message.error-email";
                    },
                };
            }
        });
    }
};