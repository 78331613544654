<template>
  <div class="weather-widget">
    <div v-if="data">
      <img :src="weatherIcon" alt="" />
      <div>
        <div class="name">
          {{ data.name }}, {{ data.sys.country.toUpperCase() }}
          <span v-if="data.weather && data.weather[0]">
            ({{ data.weather[0].description }})
          </span>
          <!-- <small>/ {{ tempUnit }}</small> -->
        </div>
        <div class="extra">
          <div class="temp celcius">
            <strong>{{ Number(data.main.temp).toFixed(0) }}</strong>
          </div>
          <div class="temp celcius">
            <span class="feels_like" :title="$t('weatherWidget.feelsLike')">{{
              Number(data.main.feels_like).toFixed(0)
            }}</span>
            <span class="min">{{ Number(data.main.temp_min).toFixed(0) }}</span>
            <span class="max">{{ Number(data.main.temp_max).toFixed(0) }}</span>
          </div>
          <div class="humidity">
            <span :title="$t('weatherWidget.humidity')">{{
              data.main.humidity
            }}</span>
          </div>
          <div class="wind">
            <span :title="$t('weatherWidget.wind')">
              <i
                class="fas fa-fw fa-arrow-up"
                :style="`transform:rotate(${data.wind.deg}deg)`"
              ></i>
              <span class="speed" :label="speed">{{
                Number(data.wind.speed).toFixed(1)
              }}</span>
            </span>
          </div>
        </div>
        <div class="extra">
          <span>
            <i class="fas fa-fw fa-sun"></i>
            {{ sunrise }}
          </span>
          <span>
            <i class="fas fa-fw fa-moon"></i>
            {{ sunset }}
          </span>
          <span>
            <i class="fas fa-eye"></i>
            {{ visibility }}<small> km</small>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WeatherAPI from "@/api/WeatherAPI";

export default {
  name: "WeatherWidget",
  data() {
    return {
      apiKey: "7d34a4418c2d258e954b21a75fa74eaa",
      data: null,
    };
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    weatherIcon() {
      /* return this.data && this.data.weather && this.data.weather[0]
        ? WeatherAPI.getConditionImageUrl(this.data.weather[0].icon, 4)
        : ""; */

      return this.data && this.data.weather && this.data.weather[0]
        ? WeatherAPI.getConditionSvg(this.data.weather[0].icon)
        : "";
    },
    sunrise() {
      return (
        this.data &&
        this.data.sys &&
        this.$moment.unix(this.data.sys.sunrise).format("HH:mm")
      );
    },
    sunset() {
      return (
        this.data &&
        this.data.sys &&
        this.$moment.unix(this.data.sys.sunset).format("HH:mm")
      );
    },
    visibility() {
      if (this.data && this.data.visibility && !isNaN(this.data.visibility))
        return (this.data.visibility / 1000).toFixed(1);
      else return "--";
    },
    speed() {
      return this.options && this.options.weatherUnits === "imperial"
        ? "miles"
        : "meters";
    },
    tempUnit() {
      if (this.options && this.options.weatherUnits === "imperial")
        return "Fahrenheit";
      else if (this.options && this.options.weatherUnits === "metric")
        return "Celsius";
      else return "Kelvin";
    },
  },
  watch: {
    options: {
      deep: true,
      handler(value, old) {
        if (value !== old) this.updateData();
      },
    },
  },
  mounted() {
    this.updateData();
  },
  methods: {
    updateData() {
      console.log("weather-options", this.options);
      if (this.options) {
        const me = this;
        const weatherAPI = new WeatherAPI(me.apiKey);

        const options = {};
        if (me.options.weatherLocation && !isNaN(me.options.weatherLocation))
          options.id = Number(me.options.weatherLocation);
        else options.q = me.options.weatherLocation || "ankara,TR";

        if (me.options.weatherUnits) options.units = me.options.weatherUnits;
        if (me.options.language) options.lang = me.options.language;

        weatherAPI
          .curentWeatherData(options)
          .then((d) => {
            console.log(d);
            me.data = d;
          })
          .catch((err) => console.log("[ERR] weather", err));
      }
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables.scss";

.weather-widget {
  user-select: none;
}

.weather-widget > div {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  color: #555;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.5);

  img {
    width: 100%;
    max-width: 150px;
  }

  & > div {
    display: flex;
    flex-direction: column;

    span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .name {
      font-style: italic;

      span {
        text-transform: capitalize;
      }
    }

    .temp {
      strong::after,
      span::after {
        content: "\00b0";
      }

      strong {
        display: inline-block;
        font-size: 300%;
        min-width: 5rem;
        text-align: right;
      }

      span.feels_like {
        display: block;
        font-weight: bold;
      }
      span.min,
      span.max {
        position: relative;
        font-style: italic;
        font-size: 80%;
        top: -0.5rem;
      }
      span.min::after {
        content: "\00b0 ~ ";
      }
    }

    .humidity > span::after {
      content: "%";
      font-size: 80%;
    }

    .extra {
      display: flex;
      flex-direction: row;

      & > div {
        margin-right: 1rem;
      }

      & > span:not(:first-of-type)::before {
        content: "\2014";
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  [title]::before {
    content: attr(title);
    display: block;
    font-size: 80%;
    font-style: italic;
    font-weight: normal;
    margin-right: 0.5rem;
    opacity: 0.5;
  }

  [label]::after {
    content: attr(label);
    font-size: 70%;
    font-weight: normal;
    opacity: 0.5;
    display: block;
    line-height: 80%;
    text-align: right;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .weather-widget {
    & > div {
      img {
        max-width: 90px;
      }
    }
  }
}
</style>