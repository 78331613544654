<template>
  <v-card class="thermostat-card probe" :color="color" :dark="dark" :elevation="elevation" :disablex="inProgress ||
    !device ||
    !device.info ||
    device.state == 2 ||
    $props.device.state === undefined
    " :disabled="inProgress || !device" :class="[
    info && $props.device.state && info.mode,
    { 'in-progress': inProgress },
    {
      unreachablex:
        $props.device.state === 2 || $props.device.state === undefined,
    },
  ]">
    <v-card-text style="flex: 1" @click="toggleThermostat">
      <div class="text-body-2">
        <strong>{{ device && device.name }}</strong><br />{{ device && roomName }}
      </div>

      <v-spacer></v-spacer>
      <v-row dense>
        <v-col cols="7">
          <div class="headline">
            <i :class="thermometer"></i>{{ info.currentTemperature }}&deg;
          </div>
        </v-col>
        <v-col cols="5" class="text-right">
          <div class="mode-info">
            <i class="mr-1"></i>{{
              info.targetTemperature && !isNaN(info.targetTemperature)
              ? info.targetTemperature
              : thermostat.temp
            }}&deg;
          </div>
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
    </v-card-text>

    <v-divider></v-divider>
    <v-btn text @click="dialog = true">
      <v-icon v-if="!inProgress" small>fas fa-ellipsis-h</v-icon>
      <v-icon v-if="inProgress" small>fas fa-cog fa-spin</v-icon>
    </v-btn>

    <!-- #region Thermostat Modal -->
    <v-dialog v-model="dialog" :persistent="inProgress" width="400">
      <v-card class="thermostat-modal" :disabled="inProgress">
        <v-card-title class="headline grey lighten-2">
          <div>{{ device && device.name }}</div>
          <div>
            <strong>{{ info && info.currentTemperature }}&deg;</strong>
          </div>
        </v-card-title>
        <v-card-text class="text-right" style="max-height: 2rem">
          <v-row>
            <v-col>
              <v-btn smal text elevation="0" :color="ecomode ? 'green lighten-1' : 'grey lighten-2'" class="eco-mode pa-2"
                @click="setEcoMode">
                <i class="ma-1 fas fa-fw fa-2x fa-leaf"></i>
                <div>eco</div>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text>
          <gauge-dimmer :min="5" :max="35" :gradient="modeGradient" sign="°" v-model="temperature" />
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-row dense>
            <v-col><v-btn color="red" text block @click="dialog = false">
                {{ $t("touchable.modal.cancel") }}
              </v-btn></v-col>
            <v-col>
              <v-btn color="primary" text block @click="setThermostat">
                {{ $t("touchable.modal.confirm") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- #endregion -->
  </v-card>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import GaugeDimmer from "./molecular/GaugeDimmer";

export default {
  components: {
    GaugeDimmer,
  },
  data() {
    return {
      dialog: false,
      temperature: 5,
      inProgress: false,
      thermostat: {
        mode: "heating",
        speed: "auto",
        temp: NaN,
        on: true,
      },
    };
  },
  props: {
    device: {
      type: Object,
      required: false,
    },
    passiveColor: {
      type: String,
      required: false,
      default: "",
    },
    activeColor: {
      type: String,
      required: false,
      default: "primary",
    },
  },
  computed: {
    ...mapGetters(["rooms"]),
    ...mapState(["socketApi"]),
    modes: {
      get() {
        return {
          heating: {
            icon: "fas fa-fw fa-sun",
            gradient: [
              { offset: 0, color: "#FFE72B" },
              { offset: 1, color: "#EE092D" },
            ],
          },
          cooling: {
            icon: "fas fa-fw fa-snowflake",
            gradient: [
              { offset: 0, color: "#E1F5FE" },
              { offset: 1, color: "#03A9FA" },
            ],
          },
          fan: {
            icon: "fas fa-fw fa-fan",
            gradient: [
              { offset: 0, color: "#CBF6DB" },
              { offset: 1, color: "#9BC472" },
            ],
          },
        };
      },
    },
    ecomode: {
      get() {
        return this.temperature > 22 && this.temperature < 26 && this.thermostat.on;
      },
    },
    modeGradient: {
      get() {
        return (
          this.modes[this.thermostat.mode] &&
          this.modes[this.thermostat.mode].gradient
        );
      },
    },
    thermometer: {
      get() {
        if (!this.info || isNaN(this.info.currentTemperature))
          return "fas fa-fw fa-exclamation";
        else if (this.info.currentTemperature >= 35)
          return "fas fa-fw fa-thermometer-full";
        else if (this.info.currentTemperature >= 30)
          return "fas fa-fw fa-thermometer-three-quarters";
        else if (this.info.currentTemperature >= 10)
          return "fas fa-fw fa-thermometer-half";
        else if (this.info.currentTemperature > 5)
          return "fas fa-fw fa-thermometer-quarter";
        else return "fas fa-fw fa-thermometer-empty";
      },
    },
    modeIcon: {
      get() {
        if (
          !this.info | !this.info.mode ||
          !/^(cooling|heating|fan)$/.test(this.info.mode)
        )
          return "fas fa-times";
        else return this.modes[this.info.mode].icon;
      },
    },
    elevation: {
      get() {
        return this.$props.device.state == 1 ? 10 : 3;
      },
    },
    info: {
      get() {
        var info = {
          currentTemperature: "--",
          fan_speed: "--",
          mode: "--",
          protectMode: false,
          sleepMode: false,
          targetTemperature: "--",
          timerHour: 0,
          timerState: null,
        };
        if (this.$props.device && this.$props.device.info) {
          info = Object.assign(info, this.$props.device.info);
        }

        if (
          !this.$props.device ||
          this.$props.device.state == undefined ||
          this.$props.device.state == 2
        )
          info.mode = "Erişilemiyor";
        else if (!this.$props.device && !this.$props.device.state)
          info.mode = "OFF";
        return info;
      },
    },
    roomName() {
      var name = "N/A";
      this.rooms.forEach((room) => {
        if (Number(room._id) == Number(this.$props.device.room_id))
          name = room.name;
      });
      return name;
    },
    color() {
      return !this.info && 
        isNaN(this.info.targetTemperature) || isNaN(this.info.currentTemperature) || 
        this.info.targetTemperature <= this.info.currentTemperature 
        ? this.passiveColor
        : this.activeColor || this.passiveColor;
    },
    dark(){
      return this.color === this.activeColor;
    }
  },
  watch: {
    dialog(dialog) {
      if (dialog && this.$props.device && !isNaN(this.info.targetTemperature)) {
        //this.thermostat.mode = this.info.mode;
        this.temperature = this.info.targetTemperature;
        //this.thermostat.speed = this.info.fan_speed;
        //this.thermostat.on = this.$props.device.state == 1;
      }
    },
    device: {
      immediate: true,
      handler(value) {
        if (value && value.set_dp && !isNaN(value.set_dp)) {
          this.thermostat.temp = Number(value.set_dp);
          this.temperature = this.thermostat.temp;
        }
        if (
          value &&
          value.info &&
          value.info.thermostat &&
          !isNaN(value.info.thermostat.targetTemperature)
        ) {
          console.log("[PROBE]", value);
          this.$set(
            this.thermostat,
            "temp",
            value.info.thermostat.targetTemperature
          );
        }
        this.$root.$forceUpdate();
      },
    },
  },
  mounted() {
    setTimeout(this.requestDeviceState, 5000);
  },
  methods: {
    setEcoMode() {
      this.$set(this, "temperature", 24);
    },
    setThermostat() {
      if (this.inProgress || !this.$props.device) return;
      const me = this;
      me.inProgress = true;

      this.thermostat.temp = this.temperature;

      me.$store.state.socketApi
        .sendCommand(me.$props.device, this.temperature, "thermostat")
        .then(() => {
          (me.dialog = false);
          this.$analytics.logEvent("action", { "probe": "set" });
        })
        .catch(() =>
          me.$notify({
            type: "error",
            title: "Cihaza erişilemedi!",
            text: "Bağlantınızı kontrol edin ve tekrar deneyin.",
          })
        )
        .finally(() => (me.inProgress = false));
    },
    toggleThermostat() {
      //
    },
    requestDeviceState() {
      if (this.socketApi && this.socketApi.connected) {
        console.log("Probe State Request =>", {
          ...this.device,
          device_type: "probe",
        });
        this.socketApi
          .getDeviceState({ ...this.device, device_type: "probe" })
          .catch(this.requestDeviceState);
      } else setTimeout(this.requestDeviceState, 5 * 1000);
    },
  },
};
</script>

<style lang="scss">
.thermostat-card.probe {
  &.primary {
    background: rgb(0, 104, 191);
    background: -moz-linear-gradient(90deg,
        rgba(0, 104, 191, 1) 0%,
        rgba(0, 151, 208, 1) 100%);
    background: -webkit-linear-gradient(90deg,
        rgba(0, 104, 191, 1) 0%,
        rgba(0, 151, 208, 1) 100%);
    background: linear-gradient(90deg,
        rgba(0, 104, 191, 1) 0%,
        rgba(0, 151, 208, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0068bf", endColorstr="#0097d0", GradientType=1);

    & * {
      color: #fff !important;
    }
  }
}
</style>