<template>
  <div class="device-drawer">
    <device-component
      v-for="(device, $index) in devices"
      :key="$index"
      :device="device"
    />
  </div>
</template>

<script>
import DeviceComponent from "./DeviceComponent.vue";

export default {
  components: { DeviceComponent },
  name: "Devices",
  data() {
    return {};
  },
  props: {
    favorites: {
      type: Boolean,
      required: false,
      default: false,
    },
    room: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    devices() {
      let devices;

      if (this.favorites) devices = this.$store.getters.favoriteDevices;
      else devices = this.$store.getters.devices;

      if (!this.room) return devices;
      else return devices.filter((device) => device.room_id == this.room);
    },
  },
};
</script>

<style lang="scss">
.device-drawer {
  padding-bottom: 3rem;
  display: block;
  min-width: 100%;
  max-width: 100%;
  overflow-y: hidden;
  width: 100%;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-items: flex-start;
}
</style>