<template>
  <div id="LocaleChanger">
    <a
      v-for="(lang, k) in locales"
      :key="k"
      @click="$root.$i18n.locale = k"
      class="flag-button"
    >
      {{ lang.displayName }}
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  computed: {
    locales() {
      const locales = {};

      if (this.$root.$i18n)
        for (var k in this.$root.$i18n.messages) {
          locales[k] = this.$root.$i18n.messages[k].display;
        }

      return locales;
    },
  },
};
</script>

<style lang="scss">
#LocaleChanger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 1rem;
}
</style>