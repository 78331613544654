import message from "./_messages";
import login from "./Login";
import settings from "./Settings";
import touchable from "./Touchable";
import scenes from "./Scenes";
import weatherWidget from "./weatherWidget";

export default {
    display: {
        rtl: false,
        displayName: "English",
        flag: require("flag-icon-css/flags/1x1/gb.svg")
    },
    message,

    login,
    settings,
    touchable,
    scenes,

    weatherWidget
};