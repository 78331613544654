<template>
  <div>
    <div>
      <SceneCard v-for="(scene, $index) in scenes"
                 :key="$index"
                 :scene="scene" />
    </div>
  </div>
</template>

<script>
import SceneCard from "@/components/touchable/SceneCard";

export default {
  name: "Scenes",
  components: {
    SceneCard,
  },
  props: {
    favorites: {
      type: Boolean,
      required: false,
      default: false,
    },
    limit: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  computed: {
    scenes() {
      var scenes;
      if (this.favorites) scenes = this.$store.getters.favoriteScenes;
      else scenes = this.$store.getters.scenes;

      if (scenes && this.limit > 0 && scenes.length > this.limit)
        scenes = scenes.filter((scene, index) => index < this.limit);

      console.log("Scenes ->", this.favorites, scenes)
      return scenes;
    },
  },
};
</script>

<style>

</style>