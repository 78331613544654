<template>
  <component refs="component" :is="icon" class="iconic" :toggle="toggle" />
</template>

<script>
export default {
  name: "Iconic",
  components: {
    na: () => import("!vue-svg-loader!@/assets/icons/n-a.svg"),
    air_conditioner: () =>
      import("!vue-svg-loader!@/assets/icons/air_conditioner.svg"),
    blind: () => import("!vue-svg-loader!@/assets/icons/blind.svg"),
    curtain: () => import("!vue-svg-loader!@/assets/icons/curtain.svg"),
    dimmer: () => import("!vue-svg-loader!@/assets/icons/dimmer.svg"),
    doorbell: () => import("!vue-svg-loader!@/assets/icons/doorbell.svg"),
    fan: () => import("!vue-svg-loader!@/assets/icons/fan.svg"),
    lamp_off: () => import("!vue-svg-loader!@/assets/icons/lamp-off.svg"),
    lamp_on: () => import("!vue-svg-loader!@/assets/icons/lamp-on.svg"),
    lampshade: () => import("!vue-svg-loader!@/assets/icons/lampshade.svg"),
    led: () => import("!vue-svg-loader!@/assets/icons/led.svg"),
    light_off: () => import("!vue-svg-loader!@/assets/icons/light-off.svg"),
    light_on: () => import("!vue-svg-loader!@/assets/icons/light-on.svg"),
    multimedia: () => import("!vue-svg-loader!@/assets/icons/multimedia.svg"),
    pulse: () => import("!vue-svg-loader!@/assets/icons/pulse.svg"),
    relay_1: () => import("!vue-svg-loader!@/assets/icons/relay-1.svg"),
    relay_2: () => import("!vue-svg-loader!@/assets/icons/relay-2.svg"),
    relay_3: () => import("!vue-svg-loader!@/assets/icons/relay-3.svg"),
    relay_4: () => import("!vue-svg-loader!@/assets/icons/relay-4.svg"),
    relay_5: () => import("!vue-svg-loader!@/assets/icons/relay-5.svg"),
    sensor: () => import("!vue-svg-loader!@/assets/icons/sensor.svg"),
    thermostat: () => import("!vue-svg-loader!@/assets/icons/thermostat.svg"),
    tv: () => import("!vue-svg-loader!@/assets/icons/tv.svg"),
    valve: () => import("!vue-svg-loader!@/assets/icons/valve.svg"),
    on_off: () => import("!vue-svg-loader!@/assets/icons/on-off.svg"),
    shutter: () => import("!vue-svg-loader!@/assets/icons/shutter.svg"),
    cog: () => import("!vue-svg-loader!@/assets/icons/cog.svg"),

    scene_default: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default.svg"),
    scene_home: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default-home.svg"),
    scene_out: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default-out.svg"),
    scene_night: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default-night.svg"),
    scene_alarm: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default-alarm.svg"),
    scene_breakfast: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default-breakfast.svg"),
    scene_dinner: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default-dinner.svg"),
    scene_movie: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default-movie.svg"),
    scene_bell: () =>
      import("!vue-svg-loader!@/assets/icons/scene-default-bell.svg"),

    menu_home: () => import("!vue-svg-loader!@/assets/icons/menu-home.svg"),
    menu_rooms: () => import("!vue-svg-loader!@/assets/icons/menu-rooms.svg"),
    menu_scenes: () => import("!vue-svg-loader!@/assets/icons/menu-scenes.svg"),
    menu_settings: () =>
      import("!vue-svg-loader!@/assets/icons/menu-settings.svg"),

    security_armed: () =>
      import("!vue-svg-loader!@/assets/icons/security-armed.svg"),
    security_disarmed: () =>
      import("!vue-svg-loader!@/assets/icons/security-disarmed.svg"),
    security_custom: () =>
      import("!vue-svg-loader!@/assets/icons/security-custom.svg"),
    security_sleep: () =>
      import("!vue-svg-loader!@/assets/icons/security-sleep.svg"),
  },
  props: {
    basePath: {
      type: String,
      required: false,
      default: "/assets/icons",
    },
    value: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "#555555",
    },
    toggle: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    icon() {
      const icon_names = Object.keys(this.$options.components);
      if (icon_names.indexOf(this.value) >= 0) return this.value;
      else if (
        icon_names.indexOf(this.value + (this.toggle ? "_on" : "_off")) >= 0
      )
        return this.value + (this.toggle ? "_on" : "_off");
      else return "na";
    },
  },
  watch: {},
  mounted() {},
  methods: {
    stylizer() {
      if (!this.$el || !this.$el.querySelector) return;
      this.$el.querySelectorAll(".cls-1").forEach((el) => {
        console.log(el);
        el.setAttribute("style", "fill:none;stroke:red;stroke-width:3px;");
      });
    },
  },
};
</script>

<style lang="scss">
svg.iconic {
  .cls-1 {
    fill: none;
    stroke: #b3b3b3;
    stroke-width: 4px;
  }
  .cls-2,
  text {
    fill: #b3b3b3 !important;
    font-weight: bold;
  }
}

svg[toggle].iconic {
  .cls-1 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 4px;
  }
  .cls-2,
  text {
    fill: #ffffff !important;
    font-weight: bold;
  }
}
</style>