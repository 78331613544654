<template>
  <component :is="deviceComponent"
             :device="device"
             :switchLabelOn="$t('touchable.switchOn')"
             :switchLabelOff="$t('touchable.switchOff')"
             :stateUpMessage="$t('touchable.stateUp')"
             :stateDownMessage="$t('touchable.stateDown')"
             :stateStopMessage="$t('touchable.stateStop')"></component>
</template>

<script>
/* import Vue from "vue"; */
import ThermostatCard from "@/components/touchable/ThermostatCard";
import KNXAirConditioner from "@/components/touchable/KNXAirConditioner.vue";
import ToggleCard from "@/components/touchable/ToggleCard";
import DimmerCard from "@/components/touchable/DimmerCard";
import CurtainCard from "@/components/touchable/CurtainCard";
import PulseCard from "@/components/touchable/PulseCard";
import ProbeCard from "@/components/touchable/ProbeCard";

export default {
  name: "DeviceComponent",
  components: {
    ThermostatCard,
    KNXAirConditioner,
    ToggleCard,
    DimmerCard,
    CurtainCard,
    PulseCard,
    ProbeCard,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /* deviceLabel() {
      return this.device && this.device.name;
    },
    deviceProps() {
      if (!this.device) return null;
      else if (this.device.icon_name === "light") {
        return {
          switchInset: false,
          icon: "fas fa-fw fa-lightbulb",
          activeIcon: "far fa-fw fa-lightbulb",
        };
      } else if (this.device.icon_name === "air_conditioner")
        return {
          icon: "fas fa-fw fa-fan",
        };
      else return null;
    }, */
    deviceComponent() {
      // console.log(this.device.icon_name);
      const isAbus =
        this.device &&
        !isNaN(this.device.address) &&
        !isNaN(this.device.channel) &&
        Number(this.device.address) > 0 &&
        Number(this.device.channel) > 0;

      if(this.device.knx_air_conditioner == "true") return "KNXAirConditioner"
      if (!isAbus && this.device.icon_name === "thermostat")
        return "ThermostatCard";
      else if (isAbus && this.device.icon_name === "thermostat")
        return "ProbeCard";
      else if (this.device.icon_name.indexOf("dimmer") >= 0)
        return "DimmerCard";
      else if (this.device.icon_name === "curtain") return "CurtainCard";
      else if (this.device.icon_name === "shutter") return "CurtainCard";
      else if (this.device.icon_name === "pulse") return "PulseCard";
      //else if (this.device.icon_name === "air_conditioner") return "PulseCard";
      else if (["on_off", "light", "air_conditioner"].indexOf(this.device.icon_name) >= 0) return "ToggleCard";
      else {
        console.log("NA =>", this.device.icon_name, this.device.name);
        return "ToggleCard";
      }
    },
  },
  /* mounted() {
    console.log(this.device);
  }, */
};
</script>
