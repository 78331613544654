<template>
  <v-card id="VerticalNavigation" color="#6e43f0" elevation="0" dark>
    <div class="spacer"></div>
    <div>
      <v-btn
        fab
        text
        :ripple="false"
        :active="!scenesDialog && !settingsDialog && !expanded"
        @click="expanded = false"
      >
        <Iconic value="menu_home" />
      </v-btn>
    </div>
    <div>
      <v-btn
        fab
        text
        :ripple="false"
        :active="!scenesDialog && !settingsDialog && expanded"
        @click="expanded = true"
      >
        <Iconic value="menu_rooms" />
      </v-btn>
    </div>

    <!-- Scenes Modal -->
    <div>
      <v-dialog scrollable v-model="scenesDialog" max-width="856">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            text
            :ripple="false"
            v-bind="attrs"
            v-on="on"
            :active="scenesDialog"
          >
            <Iconic :value="`menu_scenes`" />
          </v-btn>
        </template>

        <v-card>
          <v-card-title>{{ $t("scenes.modal.title") }}</v-card-title>
          <v-card-text> <Scenes id="ScenesModal" /> </v-card-text>
          <v-card-actions>
            <v-btn text block @click="scenesDialog = false"
              ><i class="fa fa-fw fa-times"></i
              >{{ $t("scenes.modal.close") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- Settings -->
    <div class="spacer"></div>
    <div>
      <v-dialog scrollable v-model="settingsDialog" max-width="856">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            text
            :ripple="true"
            v-bind="attrs"
            v-on="on"
            :active="settingsDialog"
          >
            <Iconic :value="'menu_settings'" />
          </v-btn>
        </template>

        <v-card>
          <v-card-title>
            {{ $t("settings.modal.title") }}
          </v-card-title>
          <v-card-text class="mt-2">
            <v-sheet rounded color="grey lighten-4" elevation="0" class="pa-4">
              <Settings ref="settings" />
            </v-sheet>
          </v-card-text>
          <v-card-actions>
            <v-btn text block @click="settingsDialog = false"
              ><i class="fa fa-fw fa-times"></i
              >{{ $t("settings.modal.dismiss") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import Settings from "@/views/Settings.vue";
import Scenes from "@/components/Scenes.vue";
import Iconic from "@/components/touchable/molecular/Iconic.vue";

export default {
  name: "VerticalNavigation",
  components: {
    Settings,
    Scenes,
    Iconic,
  },
  data() {
    return {
      settingsDialog: false,
      scenesDialog: false,
      expanded: false,
      navigation: {
        home: {
          icon: "fas fa-fw fa-home",
          svg: "menu_home",
          title: "Home",
          url: "/home",
        },
        rooms: {
          icon: "fas fa-fw fa-door-closed",
          svg: "menu_rooms",
          title: "Rooms",
          url: "/rooms",
        },
      },
    };
  },
  watch: {
    expanded(value) {
      console.log("exanded=>", value);
      if (value) document.querySelector("#Layout").classList.add("expanded");
      else document.querySelector("#Layout").classList.remove("expanded");
    },
    settingsDialog(value, old) {
      const me = this;
      setTimeout(() => {
        if (value && !old && me.$refs.settings) {
          me.$refs.settings.getSettings();
        }
      }, 300);
    },
  },
  methods: {
    setActive(key) {
      for (var n in this.navigation) {
        this.$set(this.navigation[n], "isActive", n === key);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables.scss";

$purple: rgba(111, 67, 240, 1);
$orange: #ec7700;
$white: #fff;

$gradient-min: $orange;
$gradient-max: darken($orange, 2%);

#VerticalNavigation {
  display: flex;
  flex-direction: column;
  padding: 1vh;
  box-shadow: 0 15px 15px rgba(0, 0, 200, 0.3);
  overflow: hidden;

  background: $purple;
  background: -moz-linear-gradient(
    180deg,
    $gradient-min 0%,
    $gradient-max 100%
  );
  background: -webkit-linear-gradient(
    180deg,
    $gradient-min 0%,
    $gradient-max 100%
  );
  background: linear-gradient(180deg, $gradient-min 0%, $gradient-max 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#6f43f0",endColorstr="#6f4fd1",GradientType=1);

  & > div {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & > button,
    & > button::before,
    & > button::after {
      outline: none;
      transition: none;
    }

    & > button[active] {
      background-color: $white;
      color: $purple;
      position: absolute;
      float: left;

      margin-left: 5px;

      padding-left: 16px;

      border-left: 10px solid $white;
      border-right: 35px solid $white;

      border-radius: 0;
      border-top-left-radius: 1rem;
      border-bottom-left-radius: 1rem;

      &::before,
      &::after {
        content: " ";
        position: absolute;
        display: block;
        float: right;
        height: 20px;
        right: -35px;
        /* z-index: 1; */
        opacity: 1;
        border: none;
        background: none;
        box-shadow: 20px 0 0 0 $white;
      }

      &::before {
        border-bottom-right-radius: 2rem;
        top: -20px;
      }
      &::after {
        width: 60px;
        border-top-right-radius: 2rem;
        top: 56px;
      }

      & svg path {
        fill: $orange;
        stroke: none;
      }
    }

    & > button svg {
      width: 32px;

      & path {
        fill: $white;
        stroke: none;
      }
    }
  }
}

#ScenesModal > div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  div.scene-card {
    width: 29%;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} and (orientation: landscape) {
  #VerticalNavigation {
    & > div {
      flex: 1;
    }

    & > .spacer {
      display: none;
    }
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} and (orientation: portrait) {
  #VerticalNavigation {
    flex-direction: row;
    width: 100%;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.5) !important;
    background: $orange !important;
    border-radius: 3rem!important;

    & > div {
      flex: 1;
      align-items: center;

      & > button::before,
      & > button::after {
        display: none !important;
      }

      & > button {
        border: none !important;
        padding: 0 !important;
        border-radius: 50% !important;
      }

      & > button[active] {
        color: $orange !important;
      }
    }

    & > div.spacer {
      display: none;
    }
  }

  #ScenesModal > div {
    display: flex;
    flex-direction: column;
    div.scene-card {
      width: 100%;
      margin: 1rem 0 0 0;
    }
  }
}
</style>