import Vue from "vue";

// Test via a getter in the options object to see if the passive property is accessed
var supportsPassive = false;
try {
    var opts = Object.defineProperty({}, 'passive', {
        get() {
            return (supportsPassive = true);
        }
    });
    window.addEventListener("testPassive", null, opts);
    window.removeEventListener("testPassive", null, opts);
} catch (e) { console.warn("supportsPassive", supportsPassive); }
supportsPassive = supportsPassive ? { passive: true } : false;

const holdable = (el, binding) => {
    var holdTimer;

    const holdstart = () => {
        if (holdTimer) clearInterval(holdTimer);
        holdTimer = setTimeout(() => {
            holdTimer = setInterval(binding.value, 25);
        }, 200);
    };

    const holdend = () => {
        if (holdTimer) clearInterval(holdTimer);
    };

    el.addEventListener("mousedown", holdstart);
    el.addEventListener("mouseup", holdend);
    el.addEventListener("mouseleave", holdend);
    el.addEventListener("mousecancel", holdend);

    el.addEventListener("touchstart", holdstart, supportsPassive);
    el.addEventListener("touchend", holdend);
    el.addEventListener("touchleave", holdend);
    el.addEventListener("touchcancel", holdend);
}

Vue.directive("holdable", {
    inserted: holdable,
    updated: holdable
});