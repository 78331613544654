<template>
  <v-card
    class="scene-card"
    ripple
    :disabled="inProgress"
    :class="{ 'in-progress': inProgress }"
    :color="sceneType.color"
    :style="outlineShadow"
    :icon-name="scene.icon_name"
    @click="playScene"
  >
    <v-card-text>
      <iconic :value="sceneType.icon" />
      {{ scene.name }}
    </v-card-text>
  </v-card>
</template>

<script>
import Iconic from "./molecular/Iconic.vue";

export default {
  name: "SceneCard",
  components: {
    Iconic,
  },
  data() {
    return {
      inProgress: true,
    };
  },
  props: {
    scene: {
      type: Object,
      required: true,
    },
  },
  computed: {
    prefetchValues() {
      return {
        default: { color: "#808285", icon: "scene_default" },
        home: { color: "#ff8600", colorEnd: "#ffcd00", icon: "scene_home" },
        out: { color: "#009bf8", colorEnd: "#00d7ff", icon: "scene_out" },
        night: { color: "#004bcc", colorEnd: "#0097ff", icon: "scene_night" },
        alarm: { color: "#a1071d", colorEnd: "#ff4d00", icon: "scene_alarm" },
        breakfast: { color: "#7f3f98", icon: "scene_breakfast" },
        dinner: { color: "#726658", icon: "scene_dinner" },
        movie: { color: "#ae3141", icon: "scene_movie" },
        bell: { color: "#00a651", icon: "scene_bell" },
      };
    },
    sceneType() {
      return (
        (this.scene &&
          this.prefetchValues[this.scene.icon_name] &&
          this.prefetchValues[this.scene.icon_name]) ||
        this.prefetchValues.default
      );
    },
    outlineShadow() {
      return `background:linear-gradient(90deg, ${this.sceneType.color} 0%, ${
        this.sceneType.colorEnd || this.sceneType.color
      } 100%);`;
    },
  },
  mounted() {
    console.log(this.scene);
    this.inProgress = false;
  },
  methods: {
    playScene() {
      this.inProgress = true;
      this.$store.state.socketApi
        .playScene(this.scene._id)
        .then(()=> this.$analytics.logEvent("action", {scene: this.scene.icon_name}))
        .finally(() => (this.inProgress = false));
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/touchable.scss";

.scene-card {
  @extend .touchable-card;
  min-height: auto;

  @keyframes stars {
    0% {
      stroke: rgba(255, 255, 255, 0.1);
      fill: rgba(255, 255, 255, 0.1);
    }
    50% {
      stroke: rgba(255, 255, 255, 1);
      fill: rgba(255, 255, 255, 1);
    }
    100% {
      stroke: rgba(255, 255, 255, 0.1);
      fill: rgba(255, 255, 255, 0.1);
    }
  }

  & > .v-card__text {
    margin: 0;
    padding: 0 0.5rem;
    color: #fff !important;

    & > svg.iconic {
      display: inline;
      width: 56px;
      height: auto;
      vertical-align: middle;

      .cls-1,
      .cls-2 {
        stroke: #fff;
        stroke-width: 3px;
        fill: none !important;
      }
      .cls-3 {
        animation: stars ease-in-out 3s infinite;
        fill: #fff;
        animation-delay: 1s;
      }
    }

    & > div {
      flex: 1;
    }
  }
}
</style>