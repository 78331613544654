<template>
  <div>
    <!-- Weather Widget -->
    <v-row>
      <v-col cols="12"
        ><div class="text-h5">
          {{ $t("settings.weatherWidget.header") }}
        </div></v-col
      >
      <v-col cols="12" md="6">
        <!-- <v-text-field label="Konum" v-model="weather_location">
          <template v-slot:append v-if="supportGeolocation">
            <v-btn icon @click="getLocation">
              <i class="fas fa-fw fa-crosshairs"></i>
            </v-btn>
          </template>
        </v-text-field> -->

        <v-autocomplete
          v-model="settings.weatherLocation"
          :loading="weatherWidgetSearching || weatherWidgetFetching"
          :items="weatherWidgetSearchItems"
          :search-input.sync="weatherWidgetSearch"
          :disabled="weatherWidgetFetching"
          :label="$t('settings.weatherWidget.selectCity')"
          item-text="title"
          item-value="searchIndex"
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <v-radio-group
          row
          mandatory
          v-model="settings.weatherUnits"
          :disabled="weatherWidgetFetching"
        >
          <v-radio value="metric">
            <template v-slot:label>&deg;C (Celsius)</template>
          </v-radio>
          <v-radio value="imperial">
            <template v-slot:label>&deg;F (Fahrenheit)</template>
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="settings.language"
          :items="languages"
          :label="$t('settings.setLanguage')"
          item-text="displayName"
          item-value="key"
        ></v-select>
      </v-col>
    </v-row>

    <!-- Other Settings -->
    <v-row>
      <v-col cols="12">
        <div class="text-h6">{{ $t("settings.other.header") }}</div>
      </v-col>
      <v-col cols="12">
        {{ $t("settings.other.deviceId") }}:
        <strong>{{ $store.state.user && $store.state.user.username }}</strong> /
        Version: <strong v-html="appVersion"></strong>
      </v-col>
      <v-col cols="12">
        <v-btn text :disabled="inDBRequest" @click="requestDatabaseUpdate">
          <i
            v-if="!inDBRequest"
            class="fas fa-fw fa-cloud-download-alt right-spaced"
          ></i>
          <i
            v-if="inDBRequest"
            class="fas fa-fw fa-sync-alt fa-spin right-spaced"
          ></i>
          {{ $t("settings.other.updateDatabase") }}
        </v-btn>
      </v-col>
      <v-col cols="12">
        <div class="text-right-">
          <SignOutButton />
        </div>
      </v-col>
      <PrivacyNotify />
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SignOutButton from "@/components/SignOutButton";
import PrivacyNotify from "@/components/PrivacyNotify";

export default {
  name: "Settings",
  components: { SignOutButton, PrivacyNotify },
  data() {
    return {
      inDBRequest: false,

      settings: {
        language: "",
        weatherLocation: "",
        weatherUnits: "metric",
      },
      weatherWidgetSearch: "",
      weatherWidgetSearching: false,
      weatherWidgetSearchItems: [],
      weatherWidgetFetching: false,
    };
  },
  computed: {
    ...mapGetters(["user", "userSettings", "appVersion"]),
    supportGeolocation() {
      return navigator && navigator.geolocation;
    },
    languages() {
      const languages = [];
      if (this.$root.$i18n && this.$root.$i18n) {
        for (var key in this.$root.$i18n.messages) {
          languages.push({
            ...this.$root.$i18n.messages[key].display,
            key,
          });
        }
      }
      return languages;
    },
  },
  watch: {
    weatherWidgetSearch(value, old) {
      value &&
        value !== old &&
        value.length > 3 &&
        this.searchWeatherLocation(value);
    },
    settings: {
      deep: true,
      handler(value, old) {
        // if (value === old) return;
        if (this.weatherWidgetSearching || this.weatherWidgetFetching) return;
        const me = this;
        console.log("save settings", value, old);
        value.weatherLocation &&
          value.weatherUnits &&
          me.$store.state.socketApi &&
          me.$store.state.socketApi
            .saveSettings(value)
            .then(() => {
              me.$showMessage("message.settings.saved", { type: "success" });
              me.$store.state.socketApi.loadSettings();
            })
            .catch((error) => {
              me.$showMessage("message.settings.failed", {
                type: "error",
                error,
              });
            });
      },
    },
    userSettings: {
      handler(value, old) {
        console.log("settings loaded", value, old);
      },
    },
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    ...mapActions(["searchLocation", "getLocationData"]),
    getSettings() {
      if (this.userSettings) {
        if (this.userSettings.weatherLocation) {
          this.settings.weatherUnits = this.userSettings.weatherUnits;
          this.getWeatherLocation(this.userSettings.weatherLocation);
          this.settings.language =
            this.userSettings.language ||
            (this.$root.$i18n && this.$root.$i18n.locale);
        }
      } else
        this.$store.state.socketApi.loadSettings().then(() => this.getSettings);
    },
    requestDatabaseUpdate() {
      this.inDBRequest = true;
      this.inDBRequestSuccess = false;
      this.inDBRequestError = false;
      this.$store.state.socketApi
        .requestDatabaseUpdate()
        .then(() => {
          this.$store.state.socketApi.collectData();
          this.$showMessage("message.database.updateSuccess", {
            type: "success",
          });
        })
        .catch(() =>
          this.$showMessage("message.database.updateFailed", { type: "error" })
        )
        .finally(() => (this.inDBRequest = false));
    },
    getLocation() {
      const me = this;
      if (this.supportGeolocation) {
        navigator.geolocation.getCurrentPosition((geo) => {
          console.log(geo);
          me.weather_location = [
            geo.coords.latitude,
            geo.coords.longitude,
          ].join();
        });
      } else {
        console.log("no-geolocation");
      }
    },
    searchWeatherLocation(value) {
      const me = this;
      me.weatherWidgetSearching = true;
      me.searchLocation(value)
        .then((data) => {
          //console.log(data);
          me.$set(me, "weatherWidgetSearchItems", data);
        })
        .catch((error) => console.log(error))
        .finally(() => (me.weatherWidgetSearching = false));
    },
    getWeatherLocation(locationId) {
      const me = this;
      me.weatherWidgetFetching = true;
      me.getLocationData(locationId)
        .then((data) => {
          const _items = !data || !Array.isArray(data) ? [] : data;
          me.$set(me, "weatherWidgetSearchItems", _items);
          me.$set(me.settings, "weatherLocation", locationId);
        })
        .finally(() => (me.weatherWidgetFetching = false));
    },
  },
};
</script>

<style>
</style>