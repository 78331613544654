export default {
    install(Vue) {
        /* Vue.prototype.$showMessage = (message) => {
            console.log(message);
        }; */

        Vue.mixin({
            created() {
                const vm = this;
                vm.$showMessage = (message, options) => {
                    if (!message || message == null) return message;
                    const _message = vm.$t && vm.$t(message) || message;

                    if (_message && !Array.isArray(_message) && typeof _message === "object") {
                        this.$notify({
                            ..._message,
                            ...options
                        });
                    }
                    else if (_message && typeof _message === "string") {
                        this.$notify({
                            title: _message,
                            ...options
                        });
                    }
                };
            }
        });
    }
};