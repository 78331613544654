var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pulse-card",class:[
    { 'in-pulse': _vm.inPulse },
    {
      unreachablex:
        _vm.$props.device.state === 2 || _vm.$props.device.state === undefined,
    },
  ],attrs:{"elevation":_vm.elevation,"color":_vm.stateColor,"disablex":_vm.$props.device.state === undefined},on:{"click":_vm.pulse}},[_c('v-card-text',[_c('div',{staticClass:"text-body-2"},[_c('strong',[_vm._v(_vm._s(_vm.device && _vm.device.name))]),_c('br'),_vm._v(_vm._s(_vm.device && _vm.roomName)+" ")]),(!_vm.inPulse)?_c('iconic',{attrs:{"value":_vm.device.icon_name}}):_c('iconic',{staticClass:"cog-spin",attrs:{"value":"cog"}})],1),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }