import axios from "axios";

/* const d01 = require("@/assets/weather/01d.svg"),
    d02 = require("@/assets/weather/02d.svg"),
    d03 = require("@/assets/weather/03d.svg"),
    d04 = require("@/assets/weather/04d.svg"),
    d09 = require("@/assets/weather/09d.svg"),
    d10 = require("@/assets/weather/10d.svg"),
    d11 = require("@/assets/weather/11d.svg"),
    d13 = require("@/assets/weather/13d.svg"),
    d50 = require("@/assets/weather/50d.svg"); */

const wi = {
    "01d": require("weather-icons/production/line/openweathermap/01d.svg"),
    "01n": require("weather-icons/production/line/openweathermap/01n.svg"),
    "02d": require("weather-icons/production/line/openweathermap/02d.svg"),
    "02n": require("weather-icons/production/line/openweathermap/02n.svg"),
    "03d": require("weather-icons/production/line/openweathermap/03d.svg"),
    "03n": require("weather-icons/production/line/openweathermap/03n.svg"),
    "04d": require("weather-icons/production/line/openweathermap/04d.svg"),
    "04n": require("weather-icons/production/line/openweathermap/04n.svg"),
    "09d": require("weather-icons/production/line/openweathermap/09d.svg"),
    "09n": require("weather-icons/production/line/openweathermap/09n.svg"),
    "10d": require("weather-icons/production/line/openweathermap/10d.svg"),
    "10n": require("weather-icons/production/line/openweathermap/10n.svg"),
    "11d": require("weather-icons/production/line/openweathermap/11d.svg"),
    "11n": require("weather-icons/production/line/openweathermap/11n.svg"),
    "13d": require("weather-icons/production/line/openweathermap/13d.svg"),
    "13n": require("weather-icons/production/line/openweathermap/13n.svg"),
    "50d": require("weather-icons/production/line/openweathermap/50d.svg"),
    "50n": require("weather-icons/production/line/openweathermap/50n.svg"),
};

export default class {
    constructor(apiKey, options = {}) {
        this.apiKey = apiKey;
        this.options = Object.assign({
            units: "metric",
            lang: "tr"
        }, options);
    }

    getEndPoint(endpointName) {
        return `https://api.openweathermap.org/data/2.5/${endpointName}/?appid=${this.apiKey}`;
    }

    static getConditionImageUrl(condition, size = "4") {
        return `http://openweathermap.org/img/wn/${condition}@${size}x.png`;
    }

    static getConditionSvg(condition) {
        return wi[condition];
    }

    curentWeatherData(options) {
        const me = this;
        return new Promise((resolve, reject) => {
            var opts = Object.assign({}, me.options, options);
            var params = [];
            for (var k in opts) {
                params.push(k + "=" + opts[k]);
            }

            console.log(this.getEndPoint("weather") + "&" + params.join("&"));
            axios.get(this.getEndPoint("weather") + "&" + params.join("&")).then((res) => resolve(res.data)).catch(reject);
        });
    }

}