<template>
  <v-card
    :elevation="elevation"
    :color="stateColor"
    class="pulse-card"
    @click="pulse"
    :disablex="$props.device.state === undefined"
    :class="[
      { 'in-pulse': inPulse },
      {
        unreachablex:
          $props.device.state === 2 || $props.device.state === undefined,
      },
    ]"
  >
    <v-card-text>
      <div class="text-body-2">
        <strong>{{ device && device.name }}</strong
        ><br />{{ device && roomName }}
      </div>
      <iconic :value="device.icon_name" v-if="!inPulse"/>
      <iconic value="cog" class="cog-spin" v-else />
    </v-card-text>
    <v-divider />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Iconic from "./molecular/Iconic.vue";
export default {
  name: "PulseCard",
  components: {
    Iconic,
  },
  data() {
    return {
      inPulse: false,
    };
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    pulseSeconds: {
      type: Number,
      required: false,
      default: 1,
    },
    color: {
      type: String,
      required: false,
      default: "grey lighten-5",
    },
    pulseColor: {
      type: String,
      required: false,
      default: "grey lighten-2",
    },
    pulseMessage: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    ...mapGetters(["rooms"]),
    title() {
      return this.device.name;
    },
    elevation: {
      get() {
        return this.inPulse ? 1 : 3;
      },
    },
    stateColor: {
      get() {
        return this.inPulse ? this.pulseColor : this.color;
      },
    },
    message: {
      get() {
        if (this.inPulse)
          return [
            `<i class="fas fa-fw fa-spin fa-cog"></i>`,
            this.pulseMessage
              ? `<span>${this.pulseMessage}&hellip;</span>`
              : "",
          ].join("");
        else return "<i class='fas fa-ellipsis-h'></i>";
      },
    },
    roomName() {
      var name = "N/A";
      this.rooms.forEach((room) => {
        if (Number(room._id) == Number(this.$props.device.room_id))
          name = room.name;
      });
      return name;
    },
  },
  mounted() {
    // console.log("pulse ==> ", this.device);
  },
  methods: {
    pulse() {
      if (this.inPulse) return;
      else {
        this.inPulse = true;

        this.$store.state.socketApi.sendCommand(this.device, null, "pulse")
          .then(()=> this.$analytics.logEvent("action", {pulse:this.device.icon_name}));

        setTimeout(() => {
          this.inPulse = false;
        }, this.pulseSeconds * 1000);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/touchable.scss";

.pulse-card {
  @extend .touchable-card;
  flex-direction: column;

  /* &:not(.scene-card) > .v-card__text:first-of-type {
    min-height: 174px;
  } */

  div:not(.v-progress-linear) {
    flex: 1;
  }

  &.in-pulse {
    transform: scale(0.9);
  }
}

.cog-spin{
  position: absolute;
  top: 50%;
  left: 50%;
    animation: ckw 1s linear infinite;
    fill: #aaa;
}
@keyframes ckw {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
</style>