<template>
  <div id="LoginView">
    <div>
      <v-card class="pa-2">
        <div>
          <img src="@/assets/logos/logo-aypro-future-experience.svg" alt="AYPRO" class="ma-5 mt-10" />
        </div>

        <v-alert class="text--lighten-3 text-caption" :icon="notificationIcon" :class="errors.authError && errors.authError.length > 0
            ? 'red--text'
            : 'primary--text'
          ">
          {{ $t("login.info.connectionReminder") }}
        </v-alert>

        <v-form ref="authForm" v-model="isValidForm" @submit="authorize">
          <v-carousel height="auto" :continuous="false" :cycle="false" :touchless="true" :hide-delimiters="true"
            :show-arrows="false" v-model="step" :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark">
            <v-carousel-item>
              <v-card-text>
                <!-- User ID -->
                <v-text-field outlined :label="$t('login.labels.username')" autocomplete="none" aria-autocomplete="none"
                  type="number" :rules="[$rules.required, $rules.deviceId]" v-model="authData.userid" :disabled="isBusy"
                  :error-messages="errors.deviceId" :readonly="step !== 0" autofocus />

                <div class="text-right">
                  <v-btn type="submit" color="primary" large :disabled="isBusy" :loading="isBusy">
                    {{ $t("login.buttons.next") }}
                    <i class="fas fa-fw fa-arrow-right ml-2"></i>
                  </v-btn>
                </div>
              </v-card-text>
            </v-carousel-item>
            <v-carousel-item>
              <div>
                <v-btn type="button" text @click="clearForm" :disabled="isBusy" class="text-capitalize">
                  <v-icon color="primary" class="mr-2 ml-0">fas fa-arrow-circle-left</v-icon>
                  {{ $t("login.buttons.cancel") }}
                </v-btn>
              </div>
              <v-card-text v-if="step == 1">
                <!-- Password -->
                <v-text-field outlined :label="$t('login.labels.password', [authData.userid])"
                  autocomplete="current-password" type="number" :rules="[$rules.required]" v-model="authData.password"
                  :disabled="isBusy" :error-messages="errors.authError" persistent-hint pin-code autofocus>
                </v-text-field>

                <v-row dense>
                  <v-col>
                    <a target="_blank" href="/privacy/tr/aypro-6698-kvkk-aydinlatma-metni.pdf">
                      {{ $t("message.privacy.gdpr") }}
                    </a>
                    <v-checkbox v-model="authData.gdpr" :disabled="isBusy" :rules="[$rules.required]"
                      :label="$t('message.privacy.gdprCheck')"></v-checkbox>
                  </v-col>
                </v-row>

                <v-row dense>
                  <!-- Actions -->
                  <v-col cols="12" md="6" align-self="center">
                    <v-checkbox v-model="authData.remember" :disabled="isBusy"
                      :label="$t('login.labels.remember')"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="6" align-self="center">
                    <v-btn type="submit" large block color="primary" :disabled="isBusy" :loading="isBusy">{{
                      $t("login.buttons.submit") }}</v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-carousel-item>
          </v-carousel>
        </v-form>

        <!-- Support Notification
        <v-alert
          icon="far fa-fw fa-question-circle primary--text text--lighten-3"
          class="primary--text text--lighten-3 text-caption"
        >
          {{ $t("login.info.helpText") }}
        </v-alert>
 -->
        <v-card-text>
          <LocaleChanger />
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import LocaleChanger from "@/components/LocaleChanger";

import Rules from "@/includes/formValidations";
Vue.use(Rules);

export default {
  name: "LoginView",
  components: {
    LocaleChanger,
  },
  data() {
    return {
      isBusy: false,
      step: 0,
      authData: {
        userid: "",
        password: "",
        remember: false,
        gdpr: false,
      },
      isValidForm: false,
      errors: {
        deviceId: [],
        authError: [],
      },
    };
  },
  computed: {
    notificationIcon() {
      return [
        "fas fa-fw fa-globe-europe text--lighten-2 ",
        this.errors.authError && this.errors.authError.length > 0
          ? "red--text"
          : "primary--text",
      ].join(" ");
    },
  },
  methods: {
    ...mapActions(["signIn", "deviceStatus"]),
    clearForm() {
      this.$refs.authForm.resetValidation();
      this.step = 0;
    },
    authorize($event) {
      if ($event && $event.preventDefault) $event.preventDefault();

      const me = this;
      me.errors.deviceId = [];
      me.errors.authError = [];
      //me.$refs.authForm.resetValidation();

      //me.$refs.authForm.validate();
      if (me.$refs.authForm.validate() && me.step == 0) {
        me.isBusy = true;
        me.deviceStatus(this.authData.userid)
          .then((status) => {
            if (status.isOnline) me.step = 1;
            else me.errors.deviceId.push(this.$t("message.error-nodevice"));
          })
          .finally(() => (me.isBusy = false));
      } else if (me.$refs.authForm.validate()) {
        me.isBusy = true;
        me.signIn(me.authData)
          .then((user) => {
            if (user.accessToken && user.refreshToken)
              me.$router.push({ name: "Home" });
          })
          .catch((error) => {
            console.log("authorize", error);
            const errorCode = error.message.replace(/\D/g, "");
            me.errors.authError = [
              this.$t("message.error-" + (Number(errorCode) || NaN)),
            ];
          })
          .finally(() => (me.isBusy = false));
      } else console.log("authorize", me.$refs.authForm.validate());
    }
  },
  mounted() {
    if(this.$route.params && this.$route.params.id) {
      this.$set(this.authData, "userid", this.$route.params.id);
      this.$set(this, "step", 1);
    }
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables.scss";
@import "~vuetify/src/components/VCard/_variables.scss";

#LoginView {
  display: flex;
  flex-direction: row;
  justify-self: stretch;
  align-items: center;
  justify-content: flex-end;
  height: 100%;

  background-image: url("../assets/backgrounds/interior-has-armchair-empty-white-wall.jpg");
  background-position: 0 center;
  background-repeat: no-repeat;
  background-size: cover;

  background-color: #ccc;
  border-radius: $card-border-radius;

  padding: 20px;
  margin: 15px;

  /* #region Hide Numeric Input Up-Down Buttons */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* #endregion */
}

input[type="number"][pin-code] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;

  -webkit-text-security: disc !important;
  -moz-text-security: circle !important;
  text-security: circle !important;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  #LoginView>div {
    display: flex;
    flex-direction: column;
    flex: 0.5;

    &>div {
      max-width: 25rem;
    }
  }
}

/* @media #{map-get($display-breakpoints, 'sm-and-down')} {
  #Layout {
    flex-direction: column-reverse;

    & > nav > .v-card {
      flex-direction: row;
      flex: 1;
    }
  }
} */
</style>