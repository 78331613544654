<template>
  <div></div>
</template>

<script>
const vapidKey =
  "BKmidNGa6qdh1BYx3L_s94hGhav8RUJy7NBeScTzv9PKU59jpTDPcWDBrfqqC9zgkRweLI7YV2DY0uZl5qBiu4M";
export default {
  name: "GCMComponent",
  mounted() {
    this.$hub.$on("security-ready", this.registerMessaging);
  },
  methods: {
    registerMessaging() {
      const messaging = this.$firebase.messaging();
      messaging
        .getToken({ vapidKey })
        .then((currentToken) => {
          if (currentToken) {
            console.log("[fb-messaging] ===>", currentToken);

            //send to socket
            this.$store.state.socketApi.registerGCM(currentToken);
          } else {
            console.log("[fb-messaging] ===> Permission need!");
          }
        })
        .catch((err) => {
          console.log("fb-messaging", err);
        });

      try {
        messaging.onMessage((payload) => {
          console.log("Message received. ", payload);
        });
      } catch (error) {
        console.log("onMessage [ERROR]", error);
      }
    },
  },
};
</script>

<style>
</style>