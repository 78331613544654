<template>
  <div class="time-widget">
    <div class="date">
      <span class="day-of-week">{{ $moment().format("dddd") }}</span>
      <span class="day">{{ $moment().format("D") }}</span>
      <div>
        <span class="month">{{ $moment().format("MMMM") }}</span>
        <span class="year">{{ $moment().format("YY") }}</span>
      </div>
    </div>
    <div class="time">
      <span class="hour">{{ $moment().format("HH") }}</span>
      <span class="minute">{{ $moment().format("mm") }}</span>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const me = this;
    setInterval(() => {
      me.$forceUpdate();
    }, 3 * 1000);
  },
};
</script>

<style lang="scss">
.time-widget {
  display: inline-flex;
  flex-direction: column;

  & > .date {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;

    .day {
      font-size: 350%;
      font-weight: bold;
      line-height: 80%;
      text-align: center;
    }
    .month,
    .year {
      font-size: 80%;
    }
    .year::before {
      content: "'";
      margin-left: 0.5rem;
    }
  }

  & > .time {
    align-self: left;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    margin-top: 5px;
  }

  & > .time > span.hour {
    font-size: 200%;
    vertical-align: middle;
  }
  & > .time > span:not(.hour) {
    font-size: 120%;
    vertical-align: text-bottom;
    position: relative;
    top: 0.2rem;
  }
  & > .time > span:not(:first-of-type)::before {
    content: ":";
    position: relative;
    animation: blinkDots 2s infinite;
  }
}

@keyframes blinkDots {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  51% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>