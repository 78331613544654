import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "fa"
    },
    theme: {
        themes: {
            light: {
                orange: "#ff931e",
                green: "#50b748",
                blue: "#44c4e2",
                red: "#dd454d"
            }
        }
    }
});
