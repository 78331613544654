<template>
  <v-app :dark="false" :dir="dir">
    <notifications
      position="top right"
      :duration="5000"
      :max="2"
      animation-name="flipX"
    />
    <keep-alive include="Home,Rooms">
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
    <OfflineModal />
    <v-snackbar
      bottom
      right
      :value="updateExists"
      :timeout="-1"
      color="primary"
    >
      {{ $t("message.pwa.updateNotify") }}
      <v-btn text @click="refreshApp">
        {{ $t("message.pwa.updateButton") }}
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import OfflineModal from "@/components/OfflineModal";
import update from "./mixins/update";
import { mapGetters } from "vuex";

export default {
  name: "App",
  mixins: [update],
  components: {
    OfflineModal,
  },
  data() {
    return {
      dir: "ltr",
      updateExists: false,
    };
  },
  computed: {
    ...mapGetters(["appVersion"])
  },
  watch: {
    "$root.$i18n.locale": {
      deep: true,
      handler(locale) {
        const lang = this.$root.$i18n && this.$root.$i18n.messages[locale];
        if (lang && lang.display && lang.display.rtl) this.dir = "rtl";
        else this.dir = "ltr";

        if (lang) this.$cookies.set("locale", locale);
        // console.log("locale =>", this.$root.$i18n.messages[locale].display.rtl);
      },
    },
    "$store.state.settings": {
      deep: true,
      handler(settings) {
        if (settings && settings.language && this.$root.$i18n)
          this.$root.$i18n.locale = settings.language;
      },
    },
  },
  mounted() {
    /* var userLang = navigator.language || navigator.userLanguage;
    if (userLang) this.$root.$i18n.locale = userLang.split("-")[0]; */
    const me = this;
    me.$hub.$on("showMessage", (payload) => {
      me.$showMessage(payload.message, payload.options);
    });
  },
  methods: {
    refreshApp() {
      this.updateExists = false;
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return;
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
      this.$analytics.logEvent("update", {old: this.appVersion.split(" ")[0]});
      setTimeout(() => {
        window.location.reload(true);
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "~vuetify/src/styles/settings/_variables.scss";

html,
body {
  overflow-x: hidden;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
body {
  position: relative;
}

.ps__rail-x,
.ps__rail-y {
  pointer-events: none !important;
}

.vue-notification-group {
  top: 15px !important;
  padding: 20px;
  pointer-events: none;
}
.vue-notification-group:empty {
  display: none;
}

.vue-notification {
  padding: 10px;
  margin: 3px 5px 5px;

  font-size: 14pt;

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  border-radius: 5pt;

  animation: flipInX linear 0.5s;

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }
}

i.right-spaced {
  margin-right: 0.5rem;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  body,
  #app {
    font-size: 12px;
  }

  .touchable-card {
    width: 7.5rem;
    min-height: 7.5rem;

    margin: 2vw;

    & svg.iconic {
      width: 50%;
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  #Layout {
    padding: 0!important;
    & > section {
    border-radius: 0 !important;
  }
  }
}
</style>