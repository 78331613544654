export default {

    color: {
        calculateLuma(color) {
            return Object.assign(color, { luma: 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b });
        },
        fromArray(array) {
            return "#" + array.map((n) => ("0" + Number(n).toString(16)).slice(-2)).join("");
        },
        fromRGBA(r, g, b, a = 255) {
            var color = {
                r, g, b, a,
                hex: this.fromArray([a, r, g, b])
            };
            return this.calculateLuma(color);
        },
        fromHEX(hex) {
            var result = /^#?([a-f\d]{2})?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
            var color = result ? {
                a: parseInt(result[1] || 0, 16),
                r: parseInt(result[2], 16),
                g: parseInt(result[3], 16),
                b: parseInt(result[4], 16),
                hex
            } : null;
            if (color) return this.calculateLuma(color);
            else return color;
        },
        LinearGradient(colorOffsets) {
            const canvasWidth = 101, canvasHeight = 1;
            var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d'),
                gradient = ctx.createLinearGradient(0, 0, canvasWidth, 0);

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            colorOffsets.map((colorOffset) => gradient.addColorStop(colorOffset.offset, colorOffset.color));
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);

            this.getColor = function (percentage) {
                const d = ctx.getImageData(percentage | 0, 0, 1, 1).data;
                const hex = [];
                d.map((n) => hex.push(("0" + Number(n).toString(16)).slice(-2).toString()));
                return {
                    a: d[0],
                    r: d[1],
                    g: d[2],
                    b: d[3],
                    hex: "#" + hex.join(""),
                    luma: 0.2126 * d[1] + 0.7152 * d[2] + 0.0722 * d[3]
                };
            };
        }
    }
};