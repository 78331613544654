<template>
  <div class="rooms">
    <div v-for="room in rooms" :key="room._id">
      <h2>{{ room.name }}</h2>
      <Devices :room="room._id"></Devices>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Devices from "@/components/Devices";

export default {
  name: "Rooms",
  components: {
    Devices,
  },
  computed: {
    ...mapGetters(["rooms"]),
  },
  mounted() {},
};
</script>

<style lang="scss">
.rooms {
  padding: 0;

  & > div > h2 {
    padding: 1rem 1rem 0 1rem;
  }

  & > div:not(:last-of-type) {
    margin-bottom: 3rem;
  }
}
</style>