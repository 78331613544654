export default {
    NetworkError: "Bağlantı Hatası!",
    error_403: "Oturum Açılamadı!",

    "error-500": "Sunucu hatası! Tekrar deneyin",
    "error-408": "Yönetim ekranına erişilemedi!",
    "error-required": "Gerekli!",
    "error-deviceid": "Cihazınızı kontrol edin!",
    "error-nodevice": "Yönetim ekranı bağlı değil!",

    database: {
        updating: "Veritabanı güncelleniyor...",
        updateSuccess: "Veritabanı güncellendi.",
        updateFailed: "Veritabanı güncelenemedi!"
    },

    settings: {
        loaded: "Ayarlar güncellendi",
        saved: "Ayarlar kaydedildi.",
        failed: "Ayarlar kaydedilemedi!"
    },
    connectingToDevice: "Cihaz bağlantısı kuruluyor...",
    connectingToDeviceTip: "Yönetim ekranınızın internete erişimi olduğundan emin olun",

    connection: {
        connected: "Bağlandı.",
        disconnected: "Bağlantı yok!",

    },

    pwa: {
        updateButton: "GÜNCELLE",
        updateNotify: "Yayınlanmış bir güncelleme var."
    },

    privacy: {
        gdprCheck: "Kişisel Verilerin Korunması Kanuna (KVKK) ilişkin aydınlatma metnini okudum, onaylıyorum.",
        gdpr: "Kişisel Verilerin Korunması Kanununa ilişkin Aydınlatma Metni",
        cookies: "Gizlilik ve Çerez Kullanımı"
    }
};