<template>
  <v-card :elevation="3"
          class="curtain-card"
          :class="{
            unreachablex: $props.device.state === 2,
          }">
    <v-card-text @click="dialog = true">
      <div class="text-body-2">
        <strong>{{ device && device.name }}</strong><br />{{ device && roomName }}
      </div>
      <iconic :value="device.icon_name" />
      <div class="message-text-small"
           v-html="message"></div>
    </v-card-text>
    <!-- <v-divider /> -->
    <div class="controls">
      <v-btn text
             color="grey"
             :disabled="state == 'close'"
             @click="moveDown">
        <v-icon>fas fa-chevron-down</v-icon>
      </v-btn>
      <v-btn text
             color="grey"
             @click="stop">
        <v-icon>far fa-square</v-icon>
      </v-btn>
      <v-btn text
             color="grey"
             :disabled="state == 'open'"
             @click="moveUp">
        <v-icon>fas fa-chevron-up</v-icon>
      </v-btn>
    </div>

    <!-- #region Modal -->
    <v-dialog v-model="dialog"
              :width="width">
      <v-card class="curtain-modal">
        <v-card-title :class="headlineClass">{{ title }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="7"
                   align-self="center"
                   class="text-center">
              <!-- <v-img class="ma-5" src="@/assets/icons/blind.svg" /> -->
              <iconic :value="device.icon_name" />
            </v-col>
            <v-col cols="5"
                   align-self="center"
                   class="controls text-center">
              <v-btn text
                     :disabled="state == 'open'"
                     @click="moveUp">
                <v-icon>fas fa-chevron-up</v-icon>
              </v-btn>
              <v-btn text
                     @click="stop">
                <v-icon>far fa-square</v-icon>
              </v-btn>
              <v-btn text
                     :disabled="state == 'close'"
                     @click="moveDown">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="message-text"
               v-html="message"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="grey lighten-4">
          <v-btn block
                 text
                 @click="dialog = false">
            &times; {{ $t("touchable.modal.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- #endregion -->
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Iconic from "./molecular/Iconic.vue";

export default {
  name: "CurtainCard",
  components: {
    Iconic,
  },
  data() {
    return {
      dialog: false,
      state: null,
      moveTimer: null,
    };
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    headlineClass: {
      type: String,
      required: false,
      default: "headline grey lighten-2",
    },
    width: {
      type: Number,
      required: false,
      default: 400,
    },
    pulse: {
      type: Number,
      required: false,
      default: 2,
    },
    stateUpMessage: {
      type: String,
      required: false,
      default: "Yukarı",
    },
    stateDownMessage: {
      type: String,
      required: false,
      default: "Aşağı",
    },
    stateStopMessage: {
      type: String,
      required: false,
      default: "Dur",
    },
  },
  computed: {
    ...mapGetters(["rooms"]),
    title() {
      return this.device && this.device.name;
    },
    message: {
      get() {
        if (this.state === "open" && this.stateUpMessage)
          return this.stateUpMessage + "&hellip;";
        else if (this.state === "close" && this.stateDownMessage)
          return this.stateDownMessage + "&hellip;";
        else if (this.state === "stop" && this.stateStopMessage)
          return this.stateStopMessage + "&hellip;";
        else return "<i class='fas fa-ellipsis-h'></i>";
      },
    },
    roomName() {
      var name = "N/A";
      this.rooms.forEach((room) => {
        if (Number(room._id) == Number(this.$props.device.room_id))
          name = room.name;
      });
      return name;
    },
  },
  watch: {
    device: {
      immediate: true,
      deep: true,
      handler(d) {
        console.log("[D]", d);
        if (d && d.state && ["open", "close", "stop"].indexOf(d.state) >= 0) this.changeState(d.state);
      }
    }
  },
  methods: {
    changeState(state) {
      const me = this;
      if (me.moveTimer) clearTimeout(me.moveTimer);
      me.$set(me, "state", state);
      me.moveTimer = setTimeout(() => {
        me.$set(me, "state", undefined);
        me.$set(me, "moveTimer", undefined);
      }, 2 * 1000);
    },
    moveUp() {
      if (this.state && this.state == "open") this.stop();
      else {
        this.$store.state.socketApi.sendCommand(
          this.$props.device,
          { state: "open" },
          "curtain"
        )
        .then(()=> this.$analytics.logEvent("action", {curtain:"up"}));
        this.changeState("open");
      }
    },
    moveDown() {
      if (this.state && this.state == "close") this.stop();
      else {
        this.$store.state.socketApi.sendCommand(
          this.$props.device,
          { state: "close" },
          "curtain"
        )
        .then(()=> this.$analytics.logEvent("action", {curtain:"down"}));

        this.changeState("close");
      }
    },
    stop() {
      this.$store.state.socketApi.sendCommand(
        this.$props.device,
        { state: "stop" },
        "curtain"
      )
      .then(()=> this.$analytics.logEvent("action", {curtain:"stop"}));

      this.changeState("stop");
    },
  },
};
</script>

<style lang="scss">
@import "@/sass/touchable.scss";

.curtain-card {
  @extend .touchable-card;
  flex-direction: row;

  &>div:not(.controls) {
    flex: 1;
    max-width: calc(100% - 2.5rem);
  }

  .message-text-small {
    margin-top: 1.5rem;
    line-height: initial;
  }

  .controls {
    display: flex;
    flex-direction: column-reverse;

    .v-btn {
      flex: 1;
      min-width: initial;
      padding: 0 0.5rem;
    }
  }
}

.curtain-modal {
  .controls {
    display: flex;
    flex-direction: column;
  }

  .controls>.v-btn i.v-icon {
    font-size: 250%;
  }

  .controls>.v-btn {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .message-text {
    text-align: center;
    min-height: 2rem;
  }
}
</style>