import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import SocketAPI from "@/api/SocketAPI";

Vue.use(Vuex);

var authService = "https://oauth2.aypro.com";
var socketServiceUri = "https://api.aypro.com";
var apiServiceUri = "https://api.aypro.com";
var isTest = false;

if (isTest && window.location.href.indexOf("192.168") > 0) {
  isTest = true;
  authService = "http://192.168.1.189:8001";
  socketServiceUri = "http://192.168.1.189:8002";
  apiServiceUri = "http://192.168.1.189:8002";
}
else if (isTest && window.location.href.indexOf("localhost") > 0) {
  isTest = true;
  authService = "http://localhost:8001";
  socketServiceUri = "http://localhost:8002";
  apiServiceUri = "http://localhost:8002";
}

console.log({
  authService, socketServiceUri, apiServiceUri, isTest
})

export default new Vuex.Store({
  state: {
    user: null,
    socketApi: null,
    deviceData: null,
    settings: null,
    packageVersion: require('../../package.json').version + (process.env.NODE_ENV === "production" ? "" : " <small>(Developer Mode)</small>"), // process.env.ACME || "##"
  },
  mutations: {
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
    user: (state) => {
      return state.user;
    },
    userSettings: (state) => {
      return state.settings;
    },

    devices: (state) => {
      return state.deviceData && state.deviceData.devices || null;
    },
    scenes: (state) => {
      return state.deviceData && state.deviceData.scenes;
    },
    rooms: (state) => {
      return state.deviceData && state.deviceData.rooms;
    },
    favoriteDevices: (state) => {
      return state.deviceData && state.deviceData.devices && state.deviceData.devices.filter((device) => device.favorite.toString().toLowerCase() === "true");
    },
    favoriteScenes: (state) => {
      console.log("[favorietScenes]", state.deviceData.scenes);
      return state.deviceData && state.deviceData.scenes && state.deviceData.scenes.filter((scene) => (scene.favorite == "true"));
    },
    isAuthenticated: (state) => {
      return !!state.user;
    }
  },
  actions: {
    async signIn({ state }, data) {
      console.log("[signIn]", `${authService}/api/auth`)
      const res = await axios({
        url: `${authService}/api/auth`,
        method: "post",
        withCredentials: true,
        data
      });
      state.user = res.data;
      state.socketApi = new SocketAPI(state.user.accessToken, socketServiceUri, isTest);

      if(state.user && state.user.username) {
        this._vm.$analytics.setUser(Number(state.user.username));
        this._vm.$analytics.logEvent('login', {signIn: state.user.username});
      }

      return state.user;
    },
    async signCheck({ state }) {
      const res = await axios({
        url: `${authService}/api/auth-check`,
        method: "post",
        withCredentials: true,
      });
      state.user = res.data;
      state.socketApi = new SocketAPI(state.user.accessToken, socketServiceUri, isTest);

      if(state.user && state.user.username) {
        this._vm.$analytics.setUser(Number(state.user.username));
        this._vm.$analytics.logEvent('login', {signCheck: state.user.username});
      }

      return state.user.accessToken;
    },
    async signOut({ state }) {
      await axios({
        url: `${authService}/api/auth-kill`,
        method: "post",
        withCredentials: true,
      });
      state.user = null;
      return state.socketApi && state.socketApi.destroy();
    },

    async deviceStatus(_, deviceId) {
      const res = await axios({
        url: `${authService}/api/device/${deviceId}/status`,
        method: "get",
      });
      return res.data;
    },
    roomDevices({ getters }, roomId) {
      console.log(roomId);
      return getters.devices.filter((device) => device.room_id == roomId);
    },
    getRoom({ getters }, roomId) {
      return getters.rooms.filter((room) => room._id == roomId);
    },

    async searchLocation(_, term) {
      const res = await axios({
        url: `${apiServiceUri}/geolocation/search/${term}`,
        method: "get"
      });
      return res.data;
    },

    async getLocationData(_, locationId) {
      const res = await axios({
        url: `${apiServiceUri}/geolocation/id/${locationId}`,
        method: "get"
      });
      return res.data;
    },

    async connectionCheck() {
      const res = await axios.get("/");
      return (res.data && res.status == 200);
    },
  },
  modules: {
  }
});
