<template>
  <v-card :elevation="elevation" :dark="dark" class="dimmer-card" :disablex="inProgress ||
    !$props.device ||
    $props.device.state == 2 ||
    $props.device.state === undefined
    " :disabled="inProgress || !$props.device" :class="[
    { 'in-progress': inProgress },
  ]">
    <v-card-text @click="toggle" style="flex: 1" :style="cardColor">
      <div>
        <div class="text-body-2">
          <strong>{{ device && device.name }}</strong><br />{{ device && roomName }}
        </div>
        <iconic :value="deviceIcon" />
        <span class="value" v-if="device && device.state && !isNaN(device.state.toString())"
          v-html="`${dimmerRatio}`"></span>
        <span class="value" v-else>&mdash;&mdash;</span>
      </div>
    </v-card-text>

    <v-divider></v-divider>
    <v-btn text color="grey" @click="dialog = true">
      <v-icon v-if="!inProgress" small>fas fa-fw fa-ellipsis-h</v-icon>
      <v-icon v-if="inProgress" small>fas fa-cog fa-spin</v-icon>
    </v-btn>

    <!-- #region MODAL -->
    <v-dialog v-model="dialog" :width="width">
      <v-card class="dimmer-modal">
        <v-card-title :class="headlineClass">
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <div class="mt-9 text-h4 grey--text text--lighten-1 lead-text" v-html="dimmerRatio">
              </div>
              <div class="mt-16 text-center">
                <i class="far fa-8x fa-lightbulb ml-8" :style="`color: ${$refs && $refs.dimmer && $refs.dimmer.stepColor
                  }; text-shadow: 0 0 ${iconShadow}px ${$refs && $refs.dimmer && $refs.dimmer.stepColor}`"></i>
              </div>
            </v-col>
            <v-col cols="3">
              <dimmer ref="dimmer" v-model="dimmerValue" v-bind="$props" :min="10" :max="100"
                @stepColor="(v) => stepColor = v" width="100%"></dimmer>
            </v-col>
            <v-col cols="3" align-self="end" class="text-left">
              <div>
                <v-btn text icon @click="increase" v-holdable="increase">
                  <v-icon>fas fa-fw fa-plus</v-icon>
                </v-btn>
              </div>
              <div class="mt-10">
                <v-btn text icon @click="decrease" v-holdable="decrease">
                  <v-icon>fas fa-fw fa-minus</v-icon>
                </v-btn>
              </div>
              <div class="mt-16 mb-16">
                <v-btn text icon elevation="0" color="grey" class="" @click="toggle">
                  <v-icon :class="{ 'green--text': $props.device.state == 1 }">fas fa-fw fa-power-off</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" elevation="0" text block @click="dialog = false">
            &times; {{ $t("touchable.modal.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- #endregion -->
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Dimmer from "./molecular/Dimmer";
import Iconic from "./molecular/Iconic.vue";
var cmdTimer;

export default {
  //extends: Dimmer,
  name: "DimmerCard",
  components: {
    Dimmer,
    Iconic,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    headlineClass: {
      type: String,
      required: false,
      default: "headline grey lighten-2",
    },
    width: {
      type: Number,
      required: false,
      default: 400,
    },
    defaultValue: {
      type: Number,
      required: false,
      default() {
        return this.min + (this.max - this.min) / 2;
      },
    },
    value: {
      type: Number,
      required: false,
      default() {
        return this.defaultValue;
      },
    },
    iconColor: {
      type: String,
      required: false,
      default: null,
    },
    icon: {
      type: String,
      required: false,
      default: "fa-band-aid",
    },
    activeIcon: {
      type: String,
      required: false,
      default: null,
    },
    activeIconColor: {
      type: String,
      required: false,
      default: null,
    },
    passiveColor: {
      type: String,
      required: false,
      default: "",
    },
    activeColor: {
      type: String,
      required: false,
      default: "primary",
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      inProgress: true,
      dialog: false,
      dimmerValue: 0,
      dimmerOn: true,
      passFeedback: false,
      stepColor: ""
    };
  },
  watch: {
    value(n) {
      if (!isNaN(n)) this.dimmerValue = Number(n);
      else this.dimmerValue = -1;
    },
    dimmerValue(n) {
      this.setDimmer(n);
    },
    device: {
      immediate: true,
      deep: true,
      handler(n) {
        console.log("DIMMER DEVICE =>", n);
        //if(n && n.state) this.state = n.state == true || n.state == 1;
        //this.$forceUpdate();
      }
    }
  },
  mounted() {
    this.$set(this, "dimmerValue", this.value);
    this.inProgress = false;
  },
  computed: {
    ...mapGetters(["rooms"]),
    state() {
      return this.device && this.device.state;
    },
    color() {
      return !this.state
        ? this.passiveColor
        : this.activeColor || this.passiveColor;
    },
    elevation: {
      get() {
        return this.$props.device && this.$props.device.state == 1 ? 10 : 3;
      },
    },
    roomName() {
      var name = "N/A";
      this.rooms.forEach((room) => {
        if (Number(room._id) == Number(this.$props.device.room_id))
          name = room.name;
      });
      return name;
    },
    deviceIcon() {
      return (
        (this.device &&
          this.device.icon_name &&
          this.device.icon_name.indexOf("dimmer") >= 0 &&
          "dimmer") ||
        (this.device && this.device.icon_name) ||
        null
      );
    },
    dimmerRatio() {
      if (isNaN(this.dimmerValue)) return "&mdash;&mdash;";
      else return Math.round(this.dimmerValue) + "%";
    },
    iconShadow() {
      if (!this.device || !this.state || isNaN(this.dimmerValue)) return 0;
      else return Math.round(this.dimmerValue * 0.25);
    },
    cardColor() {
      if (!this.device || !this.state || this.state == 2) return '';
      else if (!this.stepColor) return 'background-color: #fff797ff';
      else return `background-color: ${this.stepColor};`;
    },
  },
  methods: {
    increase() {
      return this.$refs.dimmer && this.$refs.dimmer.increase();
    },
    decrease() {
      return this.$refs.dimmer && this.$refs.dimmer.decrease();
    },
    toggle() {
      this.$set(this, "inProgress", true);
      setTimeout(() => this.$set(this, "inProgress", false), 500);

      if (!this.state) {
        if (isNaN(this.dimmerValue)) this.dimmerValue = 50;
        else this.setDimmer(Math.round(this.dimmerValue));
      }
      else this.$store.state.socketApi.sendCommand(this.device, !this.state, "switch");
    },
    setDimmer(value) {
      if (this.passFeedback) return;

      var offset = Math.round(value);
      if (offset < 0) offset = 0;
      else if (offset > 100) offset = 100;

      if (cmdTimer) clearTimeout(cmdTimer);

      var state = Number(offset > 0);

      cmdTimer = setTimeout(() => {
        this.passFeedback = true;
        setTimeout(() => (this.passFeedback = false), 500);

        this.$store.state.socketApi.sendCommand(
          this.device,
          { offset, state },
          "dimmer"
        )
          .then(() => this.$analytics.logEvent("action", { dimmer: state }));
      }, 200)
    }
  },
};
</script>

<style lang="scss">
@import "@/sass/touchable.scss";

.dimmer-card {
  @extend .touchable-card;
  flex-direction: column;

  svg.iconic {
    display: inline;
  }

  .small-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .value {
    display: inline-flex;
    font-size: 1.2rem;
    vertical-align: top;
    width: 50%;
    justify-content: center;
    line-height: 4rem;
  }
}

.dimmer-modal {
  .dimmer-value {
    font-size: 400%;
    font-weight: bold;
  }
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .dimmer-card {
    .value {
      line-height: 2rem;
    }
  }
}
</style>