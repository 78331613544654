export default {
    //
    weatherWidget: {
        header: "Hava Durumu",
        selectCity: "Hangi şehirde yaşıyorsunuz?"
    },

    setLanguage: "Dilinizi Seçin",

    other: {
        header: "Diğer ayarlar",
        deviceId: "Cihaz Kimliği",
        updateDatabase: "Verileri Yenile"
    },

    modal: {
        title: "Ayarlar",
        dismiss: "Kapat"
    }
};