<template>
  <fragment>
    <div
      id="ConnectionState"
      class="yellow darken-2"
      :class="{ offline: !state }"
    >
      <i class="fas fa-plug fa-3x"></i>
    </div>
    <div id="ConnectionOverlay" v-if="!connected">
      <div>
        <i class="fas fa-spin fa-circle-notch"></i>
        {{ $t("message.connectingToDevice") }}
        <p>
          {{ $t("message.connectingToDeviceTip")
          }}<small v-if="$store.state.user"
            ><br />&mdash; {{ $t("settings.other.deviceId") }}:
            {{ $store.state.user.username }} &mdash;</small
          >
        </p>
        <v-btn type="button" small color="primary" @click="endSession"
          ><i class="fas fa-power-off mr-2"></i
          >{{ $t("login.buttons.signOut") }}</v-btn
        >
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapActions } from "vuex";

// eslint-disable-next-line no-unused-vars
var stateInterval;

export default {
  name: "ConnectionState",
  data() {
    return {
      connected: false,
      state: false,
    };
  },
  props: {
    value: {
      type: Boolean,
      reuired: false,
      default: false,
    },
  },
  mounted() {
    this.$hub.$on("device-ready", () => {
      setTimeout(() => {
        this.$emit("input", true);
        this.connected = true;
      }, 2000);
    });
    this.checkState();
    stateInterval = setInterval(this.checkState, 30 * 1000);
  },
  beforeDestroy() {
    clearInterval(stateInterval);
  },
  methods: {
    ...mapActions(["deviceStatus", "signOut"]),
    checkState() {
      const me = this;
      if (this.$store && this.$store.state && this.$store.state.user)
        this.deviceStatus(this.$store.state.user.username)
          .then((status) => {
            if (status && me.state !== status.isOnline)
              this.$store.state.socketApi.getDeviceStatesByRoomId();

            me.state = (status && status.isOnline) || false;
          })
          .catch((err) => {
            console.log("deviceStatus [Error]", err);
          });
    },
    endSession() {
      this.signOut().finally(() => (window.location.href = "/"));
    },
  },
};
</script>

<style lang="scss">
#ConnectionState {
  pointer-events: none;
  user-select: none;

  display: inline-flex;
  flex-direction: column;
  align-content: center;
  vertical-align: middle;
  position: fixed;
  top: 2vh;
  right: 2vw;
  width: 4rem;
  height: 4rem;
  border: 2px solid #fff !important;
  z-index: 99999;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  transform: scale(1);
  transition: transform linear 100ms;

  &:not(.offline) {
    transform: scale(0);
  }

  & i {
    line-height: 4rem;
    animation: headShake 1s linear infinite;
  }
}

#ConnectionOverlay {
  display: block;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;

  & > div {
    text-align: center;
    width: 100%;
    margin: auto;
    text-shadow: 1px 1px 0 #ffffff, -1px -1px 1 rgba(0, 0, 0, 0.2);
    color: #ff625e;
    font-weight: bold;
    font-size: 120%;

    p {
      font-size: initial;
      font-weight: normal;
      color: #789fcc;
    }
  }
}
</style>