import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';

import "animate.css/animate.min.css";

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.$cookies.config("120d");

// Vue-Notification
import Notifications from "vue-notification";
Vue.use(Notifications);

// Firebase
import { firebase, firebaseApp, analytics } from "@/plugins/firebase";
Vue.prototype.firebase = firebase;
Vue.prototype.$firebase = firebaseApp;
Vue.prototype.$analytics = analytics;

// Broadcast
Vue.prototype.$hub = new Vue();

// Test via a getter in the options object to see if the passive property is accessed
var supportsPassive = false;
try {
  var opts = Object.defineProperty({}, 'passive', {
    get() {
      return (supportsPassive = true);
    }
  });
  window.addEventListener("testPassive", null, opts);
  window.removeEventListener("testPassive", null, opts);
} catch (e) { console.warn("supportsPassive", supportsPassive); }
Vue.prototype.$supportsPassive = supportsPassive ? { passive: true } : false;

// Fragment Plugin
import { Plugin } from "vue-fragment";
Vue.use(Plugin);

import moment from "moment";
import VueMoment from "vue-moment";
Vue.use(VueMoment, { moment });

/* PerfectScrollbar */
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';

Vue.use(PerfectScrollbar);

import "animate.css/animate.min.css";
import "vuetify/src/styles/main.sass";

// MISC
import misc from "@/includes/misc";
Vue.prototype.$misc = misc;

// Directives
import '@/includes/directives';

//#region vue-i18n
import VueI18n from "vue-i18n";
import messages from "@/locales/Common";
import './registerServiceWorker';

const locale = Vue.$cookies.get("locale") || "en";

Vue.use(VueI18n);
const i18n = new VueI18n({
  messages,
  locale,
  fallbackLocale: "en",
  localeDir: "@/locales"
});
//#endregion

// Show Message uses Vue-Notification via vue-i18n
import ShowMessage from "@/includes/showMessage";
Vue.use(ShowMessage);

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const currentUser = store.getters.isAuthenticated;

  if (requiresAuth && !currentUser) next("/login"); // redirect to login page
  /* else if (to.path === "/auth-handler" && !to.query.mode) next(eventRoutes.basePath); // undefined mode! redirect to root
  else if (requiresAuth && currentUser && !currentUser.emailVerified) next("/validate-email");
  else if (requiresAuth && currentUser && !currentUser.phoneNumber) next("/validate-phone");
  else if (requiresAuth && currentUser && !currentUser.displayName) next(eventRoutes.letUsMeet); */
  else next();
});

Vue.config.productionTip = false;

store.dispatch("signCheck")
  .finally(() => new Vue({
    i18n,
    vuetify,
    store,
    router,
    render: h => h(App)
  }).$mount('#app')
  );
